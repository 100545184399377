import React, { useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';

import useHighlightLayer from './hooks/use-highlight-layer';
import { OL_ZINDEXES } from '../../../map-viewer/config';

const sxStyles = {
    tableContainer: {
        paddingLeft: '10px',
    },
    tableCell: {
        fontSize: '0.8em',
        width: '45%'
    },
    fieldCell: {
        fontSize: '0.8em',
        minWidth: 175
    },
    tableHeader:
     {
        border: 'none',
    },
    link: {
        fontSize: '0.9em',
    }
};

const classes = {
    NBSFeatureInfoContainer: {
        padding: '15px'
    },

};

/**
* Component for rendering results from getFreatureInfo wms requests
*
* @prop (obj) map - OL map object used for drawing feature info highlights
* @prop (obj) data - json response obj from getFreatureInfo request
**/
export default function NBSFeatureInfo(props) {

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.bluetopo_highlight_layer);

    if (Object.keys(props.data).length === 0) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    }


    if (props.data.bathymetry.features && (props.data.bathymetry.features.length === 0 || props.data.bathymetry.features[0].properties.ELEVATION === null || props.data.bathymetry.features[0].properties.ELEVATION === 0.00 && props.data.bathymetry.features[0].properties.UNCERTAINTY === 0.00)) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location. Please try another location, toggle desired layers on/off, or adjust the time control, then try again.</Typography>
                <br />
            </div>
        );
    }

    // Handle drawing of GeoJSON features returned by getFeatureInfo
    clearHighlight();
    if (props.data.hasOwnProperty('bluetopo_tile_scheme') && props.data.bluetopo_tile_scheme.hasOwnProperty('features')) {
        for (const feature of props.data.bluetopo_tile_scheme.features) {
            drawFeatureHighlight(feature);
        }
    }

    let tileSchemeHeader = null;
    let tileSchemeContent = null;
    if (props.data.hasOwnProperty('bluetopo_tile_scheme')) {

        tileSchemeHeader = <div>
            <Typography style={{marginTop: 10}} align="center" color='#005da6' fontWeight='bold' psx={{margin:'10px'}}>BlueTopo Tile Scheme</Typography>
            <div style={{borderBottom: '1px solid rgba(0, 0, 0, .125)',marginLeft: 10, marginRight: 10,}}></div>
        </div>;

        if (props.data.bluetopo_tile_scheme.hasOwnProperty('features')) {

            tileSchemeContent = props.data.bluetopo_tile_scheme.features.map((feature, index) => {
                return(

                    <TableContainer sx={sxStyles.tableContainer} key={index}>
                        <Table size="small">
                            <TableBody>
                                <TableRow sx={sxStyles.tableHeader}>
                                    <TableCell><Link href={feature.properties.geotiff_link} sx={sxStyles.link} target="_blank" rel="noopener noreferrer">Download Source GeoTiff</Link></TableCell>
                                    <TableCell  align="left"><Link href={feature.properties.rat_link} sx={sxStyles.link} target="_blank" rel="noopener noreferrer">Download Source RAT</Link></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Tile Identifier:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.tile}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Tile Delivery Date:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.delivered_date} ET</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>UTM Zone Number:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.utm}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Resolution:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.resolution}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Tile Scheme Issuance:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.issuance}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
            });
        }
    }

    if (props.data.bathymetry.features && props.data.bathymetry.features.length > 0 ) {

        var bathy_coverage = props.data.bathymetry.features[0].properties.bathy_coverage
        if (bathy_coverage === 1) {bathy_coverage = "True"}
        else if (bathy_coverage === 0) {bathy_coverage = "False"}

        var coverage = props.data.bathymetry.features[0].properties.coverage
        if (coverage === 1) {coverage = "True"}
        else if (coverage === 0) {coverage = "False"}

        var significant_features = props.data.bathymetry.features[0].properties.significant_features
        if (significant_features === 1) {significant_features = "True"}
        else if (significant_features === 0) {significant_features = "False"}

        var vertical_uncert_fixed = props.data.bathymetry.features[0].properties.vertical_uncert_fixed + "m"
        if (vertical_uncert_fixed === 1000000 + "m") {vertical_uncert_fixed = "N/A"}

        var vertical_uncert_var = props.data.bathymetry.features[0].properties.vertical_uncert_var + "m"
        if (vertical_uncert_var === 1000000 + "m") {vertical_uncert_var = "N/A"}

        var horizontal_uncert_fixed = props.data.bathymetry.features[0].properties.horizontal_uncert_fixed + "m"
        if (horizontal_uncert_fixed === 1000000 + "m") {horizontal_uncert_fixed = "N/A"}

        var horizontal_uncert_var = props.data.bathymetry.features[0].properties.horizontal_uncert_var + "m"
        if (horizontal_uncert_var === 1000000 + "m") {horizontal_uncert_var = "N/A"}

        var feature_least_depth = props.data.bathymetry.features[0].properties.feature_least_depth + "m"
        if (feature_least_depth === 1000000 + "m") {feature_least_depth = "N/A"}

        var feature_size = props.data.bathymetry.features[0].properties.feature_size + "m"
        if (feature_size === 1000000 + "m") {feature_size = "N/A"}


        return(

            <div style={classes.NBSFeatureInfoContainer}>
               <center ><img src="https://www.nauticalcharts.noaa.gov/data/images/bluetopo/logo.png" alt="BlueTopo logo" width="160" height="25"/></center>
                    {
                        tileSchemeHeader
                    }
                    {
                        tileSchemeContent
                    }
                   <Typography style={{marginTop: 10}} align="center" color='#005da6' fontWeight='bold' psx={{margin:'10px'}}>BlueTopo Data & Source Info</Typography>
                   <div style={{borderBottom: '1px solid rgba(0, 0, 0, .125)',marginLeft: 10, marginRight: 10,}}></div>
                    <TableContainer sx={sxStyles.tableContainer}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Elevation:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{Number.parseFloat(props.data.bathymetry.features[0].properties.ELEVATION).toFixed(2)}m</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Uncertainty:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>+/- {Number.parseFloat(props.data.bathymetry.features[0].properties.UNCERTAINTY).toFixed(2)}m</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Source Survey ID:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data.bathymetry.features[0].properties.source_survey_id}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Survey Date Start:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data.bathymetry.features[0].properties.survey_date_start}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Survey Date End:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data.bathymetry.features[0].properties.survey_date_end}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Source Institution:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data.bathymetry.features[0].properties.source_institution}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Bathymetry Coverage:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{bathy_coverage}</TableCell>
                                </TableRow>
                                 <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Coverage:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{coverage}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Significant Features:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{significant_features}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Feature Least Depth:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature_least_depth}</TableCell>
                                </TableRow>
                                 <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Feature Size:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature_size}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Vertical Uncertainty Fixed:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{vertical_uncert_fixed}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Vertical Uncertainty Variable:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{vertical_uncert_var}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Horizontal Uncertainty Fixed:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{horizontal_uncert_fixed}</TableCell>
                                </TableRow>
                               <TableRow>
                                  <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Horizontal Uncertainty Variable:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{horizontal_uncert_var}</TableCell>
                               </TableRow>
                               <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>License Name:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data.bathymetry.features[0].properties.license_name}</TableCell>
                               </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                   <Typography style={{marginTop: 10}} align="center" color='#005da6' fontWeight='bold' psx={{margin:'10px'}}>Source Contributor Description</Typography>
                   <div style={{borderBottom: '1px solid rgba(0, 0, 0, .125)',marginLeft: 10, marginRight: 10,}}></div>
                       <TableContainer sx={sxStyles.tableContainer}>
                           <Table size="small">
                           <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Source Institution</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>The institution responsible for the survey.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Coverage</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>A binary statement expressing if full seafloor coverage has been achieved in the area covered by hydrographic surveys. If false, the bathy_coverage attribute must also be false. If true, bathy_coverage may either be true or false. </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Bathymetry Coverage</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>A binary expression stating if full bathymetric coverage has been achieved in the area covered by hydrographic surveys. If true, this indicates the value is sourced from a measured depth, not an interpolated depth. If false, no depth measurement was achieved.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Significant Features</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>A binary indication that a systematic method of exploring the seafloor was undertaken to detect significant features. If false, feature_size and feature_least_depth attributes are both not applicable.In the context of bathymetry, a feature is any object, whether manmade or not, projecting above the seafloor, which may be considered a danger to surface navigation. 1 = True 0 = False</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Feature Least Depth</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>A binary expression of the ability of the survey to detect the least depth of features measured. 1 = True 0 = False</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Feature Size</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>The size of the smallest feature that the survey was capable of detecting with a high probability - unit is cubic meters.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Vertical Uncertainty Fixed</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>The best estimate of the accuracy of depths, heights, vertical distances and vertical clearances. Reported at a 95% Confidence Interval.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Vertical Uncertainty Variable</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>The best estimate of the variable accuracy of depths, heights, vertical distances and vertical clearances. Reported at a 95% Confidence Interval.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Horizontal Uncertainty Fixed</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>The best estimate of the fixed accuracy of a position. Reported at a 95% Confidence Interval.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Horizontal Uncertainty Variable</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>The best estimate of the variable accuracy of a position as a multiplier of depth. Reported at a 95% Confidence Interval.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>License Name</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>The license information regarding restrictions on data redistribution, usage, and source attribution.</TableCell>
                            </TableRow>

                           </TableBody>
                        </Table>
                    </TableContainer>

            </div>
        );

    } else {
        return(null);
    }
};
