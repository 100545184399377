import React from 'react';
import { Select, InputLabel, MenuItem, Typography, CircularProgress, Button, Tooltip, IconButton, ButtonGroup} from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import NBSLegend from '../legend-menu-items/nbs-legend.js';


// NOTE: once we have developed more content for the menu item body contents, it may be useful to
// create a NowCoastMenuItemBody component that can be shared by all nowcoast menu items to make this
// formatting reusable for each menu item. (assuming they are uniform enough)
const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* Renders check to toggle tiles cheme layer
*
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources" (or just an on value)
* @prop (func) updateOlLayerState - callback for updating olLayerState
*/
function NBSTileSchemeControl(props) {
    return (
        <FormGroup sx={{marginLeft: "3.25em"}}>
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["bluetopo_tile_scheme"].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState["bluetopo_tile_scheme"].on}, "bluetopo_tile_scheme")}} />}
                label={<Typography variant="caption">BlueTopo Tile Scheme</Typography>}
            />
        </FormGroup>
    );
}

/**
* Renders select to choose style to display for nbs layer - updates olLayerState stylesParam
*
* @prop (obj) activeStylesList - list of currently active styles (style values correspond to layers in dynamic layers list)
* @prop (func) updateOlLayerState - callback for updating olLayerState
* @prop (array) nbsStyleInfo - list of available styles for bathymetry layer
*/
function NBSStyleSelect(props) {

    //NOTE: this is hardcoded, as it is a custom component. It would be possible though
    //to look at styleInfo and determine if a style select
    //should be created, thus having them automatically formed in response to configuration changes.
    //To do this look in styleInfo for each layer in this product and see if
    //the array of style info has a length > 1. If so then there are as many dynamic style options as elements
    //in that array and dropdowns can be created for each of those layers
    //BUT WAIT: how the default value be selected? (top of the list, or is a config/hardcoding required to define defaults?)
    // an alternative is to handle this exactly like dynamic sourceLayers:
    //  1. add flag to config like "dynamicStyles:true" for layers you want to use,
    //  2. grab the STYLES list from the object defined in config.js to get defaults for dynamicStyleLists in app.js
    // but handling it here avoids adding that flag to all products in config

    // (Still may consider joining dynamicLayerLists and dynamicStyleLists into one object at some point.)
    // Update: have merged both objects into olLayerState.

    if (!props.nbsStyleInfo) {
        return (
            <div><CircularProgress /></div>
        );
    }

    const nbsStyleOptions = props.nbsStyleInfo.bathymetry.map((styleInfo,i) => {
        var title = styleInfo.title

        if (title.includes("_")) {
                const titleCaps = title.replaceAll("_"," ").split(' ').map(
                    w => w[0].toUpperCase() + w.substring(1).toLowerCase())
                   .join(' ');
                title = titleCaps
        }
        if (title.includes("coverage")) {
                title = "Coverage"
        }

        return (
            <MenuItem key={i} value={styleInfo.name}>{title}</MenuItem>
        );
    });

    return (
        <div>
            <InputLabel id="nbs_select_label" sx={{fontSize: '90%'}}>Select Data Type</InputLabel>
            <Select
                autoWidth
                size="small"
                labelId="nbs_select_label"
                value={(props.activeStylesList) ? props.activeStylesList[0] : 'nbs_elevation'} // Should always be a singleton list for nbs
                onChange={(e) => props.updateOlLayerState({'stylesParam': [e.target.value]}, 'bathymetry')} // Hardcoded key corresponding to ol layer name
            >
                {nbsStyleOptions}
            </Select>
        </div>
    );
}

/**
* NBSayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) nbsActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setNbsActive - callback for setting nbsActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (obj) activeStylesList - list of currently active styles (style values correspond to layers in dynamic layers list)
*   @prop (array) styleInfo - list of available styles for bathymetry layer
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function NBSLayerMenuItem(props){
    let currentAbstract = <>

    <Typography variant="caption"> BlueTopo is a compilation of the nation's best available bathymetric data. In
    the same way that a topographic map details the height of land, BlueTopo details the depth of lake beds and seafloor
    beneath navigationally significant U.S. waters. Created as part of the Office of Coast Survey nautical charting
    mission and its National Bathymetric Source project, BlueTopo is curated bathymetric source data to provide a
    definitive nationwide model of the seafloor and the Great Lakes. </Typography>
    <br /><br />
    <Typography variant="caption">This service is not for navigation and the bathymetry is compiled from multiple
    sources with varying quality and includes forms of interpolation. The bathymetric quality and interpolation is
    described through the vertical uncertainty layer and eventually the contributor layer. Products generated with
    these data are not to be used for measurements or navigation. The vertical coordinate reference system for BlueTopo
    is included as metadata in each GeoTIFF and can be accessed by downloading the data via the BlueTopo Tile Scheme
    layer on the nowCOAST map viewer. The products are referenced to the current vertical datums for the United States
    as specified at <a href="https://geodesy.noaa.gov/datums/vertical/index.shtml" target="_blank" rel="noopener noreferrer">NOAA Vertical Datums</a> and
    using <a href="https://geodesy.noaa.gov/GEOID" target="_blank" rel="noopener noreferrer"> NGS GEOID</a> models.
    The relationship of tidal datums to various vertical coordinate reference systems are modeled through NOAA VDatum
    gridded transforms; for VDatum terms of use see <a href="https://vdatum.noaa.gov/download_agreement.php" target="_blank" rel="noopener noreferrer"> VDATUM</a>.
    Navigational datums used by NOAA represent a further distinction to this assembly, as defined through the publication
    of official nautical chart data. For further information see <a href="https://www.nauticalcharts.noaa.gov/data/bluetopo.html" target="_blank" rel="noopener noreferrer"> BlueTopo</a>.
    </Typography>

    </>;

    return (
        <LayerMenuItem
            layerName={"nbs"}
            label={"BlueTopo"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.nbsActive}
            setLayerIsActive={props.setNbsActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"nbs"}
                    layersList={props.layersList}
                    infoContent={currentAbstract}
                    legendContent={
                        props.styleInfo ?
                            <NBSLegend
                                activeStyle={(props.activeStylesList) ? props.activeStylesList[0] : 'nbs_elvation'}
                                nbsStyleInfo={props.styleInfo}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}

                >
                    <Typography>High-Resolution Bathymetry</Typography>
                    <NBSStyleSelect
                        activeStylesList={props.activeStylesList}
                        updateOlLayerState={props.updateOlLayerState}
                        nbsStyleInfo={props.styleInfo}
                    />
                    <NBSTileSchemeControl
                        updateOlLayerState={props.updateOlLayerState}
                        olLayerState={props.olLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default NBSLayerMenuItem;