import React, { Fragment } from "react";
import { Typography, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import SurfaceObsLegend from '../legend-menu-items/surface-obs-legend.js';

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

const sxStyles = {
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
};

/**
* ObsOptions: Check boxes for toggling layers
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/

function ObsOptions(props) {
    return(
        <FormGroup>
            <FormLabel><Typography variant="overline">Surface Weather & Ocean Observations<br></br></Typography></FormLabel>
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["cached_stations"].on && props.olLayerState["static_clusters"].on}
                onChange={() => {
                    props.updateOlLayerState({'on': !props.olLayerState["cached_stations"].on}, "cached_stations");
                    props.updateOlLayerState({'on': !props.olLayerState["static_clusters"].on}, "static_clusters");
                }} />}
                label={<Typography variant="caption">Weather Observations (Latest)</Typography>}
            />
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["co_ops_stations"].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState["co_ops_stations"].on}, "co_ops_stations")}} />}
                label={<Typography variant="caption">Water Level Observations (Last 3 Days)</Typography>}
            />
        </FormGroup>
    );

}

/**
* SurfaceObsLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) productActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setProductActive - callback for setting encActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*/
function SurfaceObsLayerMenuItem(props){

    let currentAbstract = <>
        <Typography variant="caption">Latest in-situ surface weather and marine weather observations at observing sites using the World
            Meteorological Organization's  international station model. The station model is a method for representing weather data
            collected at an observing platform on a weather map using standard symbols and numbers. The station model depicts present
            weather conditions, cloud cover, wind direction, wind speed, wind gust, visibility, air temperature, dew point temperature,
            air pressure adjusted to mean sea level, and the change in air pressure over the last 3 hours. In addition, for observations
            from marine sites such as coastal stations, buoys, and ships, the station model also includes sea surface water temperature
            and significant wave height. The circle in the model is centered over the latitude and longitude coordinates of the observing
            platform. Presently, observations are displayed from airports (AWOS, ASOS, manual), fixed buoys, C-MAN stations, and ships
            participating in the Voluntary Observing Ship (VOS) program. Observations from other observing networks (e.g. NOS NWLON and PORTS)
            will be added when available from the NWS map service. The observations cover CONUS and U.S. territories and also other countries
            which make their data available to the public. For most observing platforms, the observations are updated hourly.</Typography>
            <br /><br />
        <Typography variant="caption">Source: NWS</Typography>
            <br /><br />
        <Typography variant="caption">Latest in-situ water level observations recorded at NOAA/National Ocean Service’s National Water Level
            Observation Network (NWLON) stations and NOS Physical Oceanographic Real-Time System (PORTS) stations. NWLON consists of 210
            permanent stations on the coasts and Great Lakes. PORTS is a network of ocean and weather mesonets located at 39 water bodies
            (e.g. bays, sounds, or locks) on the coasts and the Great Lakes. The location of the water level stations are indicated by the
            official International Hydrographic Organization’s tide gauge symbol along with the NOS station identifier. The water level
            observations for the past three days at 6-minute intervals are displayed using time-series plots. For Great Lakes stations, time
            series plots are displayed for both water level observations referenced to the Great Lakes Low Water Datum (LWD) and the International
            Great Lakes Datum of 1985 (IGLD85). For non-Great Lakes one can choose from different tidal datums including 1) Mean Higher High Water
            (MHHW), 2) Mean High Water (MHW), 3) Mean Tide Level (MTL), 4) Mean Sea Level (MSL), 5) Mean Low Water (MLW), and 6) Mean Lower Low Water
            (MLLW), as well as the Station Datum (STND), North American Vertical Datum of 1988 (NAVD) for some stations and the Columbia River Datum
            (CRD) for certain stations on the Columbia River. The observations should be considered ‘Preliminary’ since the data have not been subjected
            to quality control or quality assurance procedures and do not meet the criteria and standards of official NOS data. The data should be used
            only with appropriate caution. In addition, for non-Great Lakes stations, the time series plots also display NOS’ Astronomical Tidal
            Predictions.</Typography>
            <br /><br />
        <Typography variant="caption">Sources: NOS/CO-OPS</Typography>
    </>;

    const links = <>
        <Typography variant='caption'>External Services</Typography>
        <br />
        <Fragment>
            <Typography variant='caption' sx={sxStyles.links}>
                <a href={"https://mapservices.weather.noaa.gov/vector/rest/services/obs/surface_obs/MapServer/"}
                target="_blank" rel="noopener noreferrer">Surface & Marine Weather Observations</a>
            </Typography>
            <br />
        </Fragment>
        <Fragment>
            <Typography variant='caption' sx={sxStyles.links}>
                <a href={"https://mapservices.weather.noaa.gov/static/rest/services/NOS_Observations/CO_OPS_Stations/MapServer/"}
                target="_blank" rel="noopener noreferrer">CO-OPS Water Level Station Observations</a>
            </Typography>
            <br />
        </Fragment>
    </>;

    return (
        <LayerMenuItem
            layerName={"surface_obs"}
            label={"Surface Observations"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.productActive}
            setLayerIsActive={props.setProductActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"surface_obs"}
                    layersList={props.layersList}
                    infoContent={currentAbstract}
                    legendContent={
                        <div>
                            <FormLabel><Typography variant="overline">EXTERNAL SERVICE</Typography></FormLabel>
                            <Typography sx={{mb:'10px',mt:'10px'}}>Surface & Marine Weather Observations</Typography>
                            <SurfaceObsLegend/>
                        </div>
                    }
                    capUrlsContent={links}
                >
                    <ObsOptions
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default SurfaceObsLayerMenuItem;