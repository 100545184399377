import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


const sxStyles = {
    accordionText: {
        fontSize: '90%',
    },
};

const FeatureAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&$expanded': {
        margin: 'auto',
    },
}));

const FeatureAccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: '0.0em',
    '&$expanded': {
        minHeight: '1em',
    },
    '& .MuiAccordionSummary-content': {
        '&$expanded': {
            margin: '12px 0',
        },
    },
}));

const FeatureAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
}));


/**
*   Used to create expandable feature info container for various products within map click popup
*   Common use case is to highlight a feature on the map when the accordion containing that feature is expanded
*
*   NOTE: This is an uncontrolled component ie. we do not attempt to open/close the accordion based on external
*`        state/props. However we commonly want to watch the state in order to perform actions outside of the accordion
*         when it is opened (highlighting of features) for this do track the state externally but we are not
*         attempting to control the accordion state, we are just watching it

*   Another common use is to add a color to the left side of the accordion header to associate it with the feature on
*   the map. This can be done via CSS using the styleOverride prop
*
*   Contents of the accordion to be show when it is expanded should be passed via children prop
*
*   @prop (obj) styleOverride - additional css to apply to feature header only
*   @prop (obj) sxStyleOverride - additional css to apply to feature header only (passed to sx prop) (optional)
*   @prop (bool) defaultExpanded - initial accordion open/closed state t/f
*   @prop (func) setFeatureIsOn - setter for watching open/close state of accordion if tracking state outside of component
*   @prop (string) featureName - Text to be displayed in AccordionSummary (name of feature)
*   @prop (jsx) children - any components to be displayed within the expanded feature info
*/
export default function FeatureInfoAccordion(props){

    if (props.setFeatureIsOn) {
        // Inner accordion expanded state is tracked by external state (but not controlled)
        return(
            <FeatureAccordion
                defaultExpanded={props.defaultExpanded}
                onChange={props.setFeatureIsOn}
            >
                <FeatureAccordionSummary
                    style={props.styleOverride}
                    sx={props.classOverride}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography sx={sxStyles.accordionText}>{props.featureName}</Typography>
                </FeatureAccordionSummary>
                <FeatureAccordionDetails>
                    {props.children}
                </FeatureAccordionDetails>
            </FeatureAccordion>
        );
    } else {
        // Regular uncontrolled/untracked accordion
        return (
            <FeatureAccordion
                defaultExpanded={props.defaultExpanded }
            >
                <FeatureAccordionSummary
                    style={props.styleOverride}
                    sx={props.classOverride}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography sx={sxStyles.accordionText}>{props.featureName}</Typography>
                </FeatureAccordionSummary>
                <FeatureAccordionDetails>
                    {props.children}
                </FeatureAccordionDetails>
            </FeatureAccordion>
        );
    }
}
