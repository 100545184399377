import React, { useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import STOFSLegend from '../menu/legend-menu-items/stofs-legend.js';

const classes ={
    STOFSFeatureInfoContainer: {
        padding: '10px',
    }
};


/**
* Component for rendering results from getFreatureInfo wms requests
*
* @prop (obj) data - json response obj from getFreatureInfo request
* @prop (obj) styleInfo - legend info that pertains only to MRMS (derived from top-level state obj: styleInfo)
**/
export default function STOFSFeatureInfo(props) {

    if (Object.keys(props.data).length === 0) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    }

    if (props.data.features && props.data.features.length > 0) {
        return(
            <div style={classes.STOFSFeatureInfoContainer}>
                <Typography align="center">STOFS3D Inland-Coastal Flooding</Typography>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10,
                    }}
                >
                </div>
                <br />
                <Typography sx={{fontSize: '0.8em'}} align="center">Vertical Datum: {props.data.features[0].properties.verticaldatum}</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}} align="center">Disturbance Min: {props.data.features[0].properties.minwaterlevel} meters</Typography>
                <Typography sx={{fontSize: '0.8em'}} align="center">Disturbance Max: {props.data.features[0].properties.maxwaterlevel} meters</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}} align="center">Issuance Time: {props.data.features[0].properties.issuance}</Typography>
                <Typography sx={{fontSize: '0.8em'}} align="center">Valid Forecast Cycle: {props.data.features[0].properties.reference}</Typography>
                <Typography sx={{fontSize: '0.8em'}} align="center">Valid Forecast Hour: {props.data.features[0].properties.valid}</Typography>
                <br />
                { (props.styleInfo) ?
                    <center><STOFSLegend
                        STOFSStyleInfo={props.styleInfo}
                    /></center>
                    : null
                }
            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}