import React, { useState } from 'react';
import { Grid, Switch, CircularProgress, Typography, IconButton} from '@mui/material';

import LayersClearIcon from '@mui/icons-material/LayersClear';
import TuneIcon from '@mui/icons-material/Tune';
import { CONVECTIVE_LAYERS } from '../../../config.js';


const sxStyles = {
    menuItemHeaderContainer : {
        width: '100%',
        direction:'row',
        alignItems:'center',
        justify:'space-evenly',
        overflow: 'hidden',
    },
    menuButton: {
        marginTop: '0.1em'
    },
    deleteButton: {
        marginTop: '0.1em'
    },
    switchButton: {
        marginTop: '0.1em',
    },
    LayerMenuTitle: {
      marginLeft: '5px',
      fontSize: 14.5,
      cursor: "pointer",
      userSelect: "none"
    },
    bodyContainer: {
        boxShadow: 'inset 0px 6px 4px -4px rgba(0, 0, 0, 0.25), inset 0px -6px 4px -4px rgba(0, 0, 0, 0.25)',
    },
};

const classes = {
    layerDivider: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginLeft: 4,
        marginRight: 4,
    },
};

/**
* MenuItemHeader: Main high-level control for a menu item
*   - Contains switch to toggle layer, layer name, and if applicable a button to open layer menu and/or a button to
*       remove the layer from active layers
*
*   @prop (string) layerName - name of layer (must match a key in props.layerToggles (top level key from LAYERS in config.js))
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerIsActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setLayerIsActive - callback for setting layerIsActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) noBodyMenu - true if layer does not have menu to open up for extra controls
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (string) label - label that will appear on the layer control
*   @prop (bool) bodyIsOpen - true if body menu is open
*   @prop (func) setBodyIsOpen - callback for changing value of bodyIsOpen
*   @prop (func) updateOlLayerState - optional - callback for updating olLayerState
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (list) layersList - Optional - list of strings containing dynamic layer names turned on for particular layer
*
*/
function MenuItemHeader(props) {
    const handleSwitch = (e) => {
        if (typeof(props.layersList) !== "undefined" && typeof(props.updateOlLayerState) !== "undefined") {
            // Special Case: When turning on cyclones from a state where all dynamic layers were off, turn arbitrary defaults
            if (props.layersList !== null && props.layersList.length === 0 && !props.layerToggles[props.layerName]) {
                // The switch was off and all dynamic layers are off, so turn on a specific dynamic layer as switch goes on
                if (props.layerName === "tropical_cyclones"){ // Hack for tropical_cyclones layer to meet UI behavior requirements
                    // Storm surge boxes must also be unchecked before turning on the track
                    if (!props.olLayerState.tropical_ss.on) {
                        props.updateOlLayerState({'layersParam': ["tropical_cyclone_track_forecast", "tropical_cyclone_intensity_forecast"], 'on': true}, 'tropical_cyclones');
                    }
                }
            }
        }

        if ((props.layerName === "nbs" || props.layerName === "s111" || props.layerName === "stofs" || props.layerName === "sst" || props.layerName === "s100" || props.layerName === "pathogen" || props.layerName === "mrms_qpe") && !props.layerToggles[props.layerName]) {
            // Special Case: Only when turning on OFS, NBS, SST, or STOFS layers. Turn every other layer off
            const newLayerToggles = {};
            for (const product in props.layerToggles) {
                // Turn off all layers except the layer for the current menu item (and basemaps)
                if (product === props.layerName) { // layer for this component is nbs or s111
                    Object.assign(newLayerToggles, {[product]: !props.layerToggles[props.layerName]});
                } else if (product === "basemaps") {
                    Object.assign(newLayerToggles, {[product]: true}); //basemaps are always on
                } else {
                    if(props.layerName !== "sst" || (props.layerName === "sst" && product !== "surface_obs")) {
                        Object.assign(newLayerToggles, {[product]: false});
                    }
                }
            }
            props.updateLayerToggles(newLayerToggles);
        } else if (props.layerName === "ltng_den") {
            //turn on satellite and turn off all other layers
            const newLayerToggles = {};
            Object.assign(newLayerToggles, {[props.layerName]: !props.layerToggles[props.layerName]});
            for (const product in props.layerToggles) {
                if (product === "basemaps") {
                    Object.assign(newLayerToggles, {[product]: true});
                } else if (product === "satellite") {
                    if(!props.layerToggles[product]) {
                        Object.assign(newLayerToggles, {[product]: newLayerToggles.ltng_den});
                    }
                } else if (product !== "surface_obs" && product !== "satellite" && product !== "ltng_den") {
                    if(!props.layerToggles[props.layerName]) {
                        Object.assign(newLayerToggles, {[product]: false});
                    }
                }
            }
            props.updateLayerToggles(newLayerToggles);
        } else if (props.layerName === "convective_outlooks") {
            const newLayerToggles = {};
            //to handle convective activity chart behavior
            var convectiveDay1Layers = Object.values(CONVECTIVE_LAYERS).filter(obj => {return obj.day === "1"});
            var day1LayerOn = false;
            for(const layer of convectiveDay1Layers) {
                if(props.olLayerState[layer.layer_name].on) {
                    day1LayerOn = true;
                    break;
                }
            }
            Object.assign(newLayerToggles, {[props.layerName]: !props.layerToggles[props.layerName]});
            for (const product in props.layerToggles) {
                if (product === "satellite") {
                    if(props.layerToggles[product]) {
                        Object.assign(newLayerToggles, {[product]: !newLayerToggles.convective_outlooks});
                    }
                } else if ((product === "wwa" || product === "mrms") && day1LayerOn && !props.layerToggles[product]) {
                    Object.assign(newLayerToggles, {[product]: newLayerToggles.convective_outlooks});
                }
            }
            props.updateLayerToggles(newLayerToggles);
        } else {
            // Normal Case: Just toggle layer for current menu item
            props.updateLayerToggles({[props.layerName]: !props.layerToggles[props.layerName]});
        }

        // If layer is being turned off, collapse the menu body
        if (props.layerToggles[props.layerName]) {
            props.setBodyIsOpen(false);
        }

        // If layer is being turned on, set to active
        if (!props.layerToggles[props.layerName]) {
            props.setLayerIsActive(true);
        }

    };


    const handleDelete = (e) => {
        props.setLayerIsActive(false);
        if (props.layerToggles[props.layerName]) {
            props.updateLayerToggles({[props.layerName]: false});
        }
    };

    return (
        <Grid container sx={sxStyles.menuItemHeaderContainer}>
            <Grid item xs={2}>
                {(props.layerInitialized) ?
                    <Switch size="small"
                        sx={sxStyles.switchButton}
                        checked={props.layerToggles[props.layerName]}
                        onChange={handleSwitch}
                    /> :
                    <CircularProgress />
                }
            </Grid>
            <Grid item xs={(props.onlyDisplayActive && !props.noBodyMenu) ? 7 : 8}>
                   <Typography sx={sxStyles.LayerMenuTitle} variant="body2" align="left" onClick={(e) => {props.setBodyIsOpen(!props.bodyIsOpen)}} >{props.label}</Typography>
            </Grid>
            {(props.noBodyMenu) ?
                null :
                <Grid item xs={1}>
                    <IconButton size="medium" color="secondary" sx={sxStyles.menuButton} onClick={(e) => {props.setBodyIsOpen(!props.bodyIsOpen)}} ><TuneIcon /></IconButton>
                </Grid>
            }
            {(props.onlyDisplayActive) ?
                <Grid item xs={2}>
                    <IconButton color="secondary" sx={sxStyles.deleteButton} onClick={handleDelete} ><LayersClearIcon /></IconButton>
                </Grid> : null
            }
        </Grid>
    );
}

/**
* MenuItemBody: pass through for child components (content of layer controls menu)
*
*   @prop (jsx) children - any components to be displayed in the body menu
*/
function MenuItemBody(props) {
    return(
        <div>{props.children}</div>
    );
}


/**
* LayerMenuItem: consists of MenuItemHeader which controls layer and may be expanded to display MenuItemBody
*   which may be used to display extra controls/info for the layer
*
*   @prop (string) layerName - name of layer (must match a key in props.layerToggles (top level key from LAYERS in config.js))
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerIsActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setLayerIsActive - callback for setting layerIsActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (string) label - label that will appear on the layer control
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (list) layersList - Optional - list of strings containing dynamic layer names turned on for particular layer
*   @state (bool) bodyIsOpen - true if body menu is open, else false/closed
*/
function LayerMenuItem(props) {

    const [bodyIsOpen, setBodyIsOpen] = useState(false);

    let displayLayerMenuItem = true;
    if (props.onlyDisplayActive) {
        if(!props.layerIsActive) {
            displayLayerMenuItem = false;
        }
    }

    return (
        (displayLayerMenuItem) ?
            <div sx={sxStyles.menuItemContainer}>
                <Grid container>
                    <Grid item xs={12}>
                        <MenuItemHeader
                            layerName={props.layerName}
                            label={props.label}
                            bodyIsOpen={bodyIsOpen}
                            setBodyIsOpen={setBodyIsOpen}
                            layerToggles={props.layerToggles}
                            updateLayerToggles={props.updateLayerToggles}
                            layerInitialized={props.layerInitialized}
                            setLayerIsActive={props.setLayerIsActive}
                            onlyDisplayActive={props.onlyDisplayActive}
                            activeLayers={props.activeLayers}
                            setActiveLayers={props.setActiveLayers}
                            noBodyMenu={(!props.children) ? true : false} // if no children passed, then there will be no body menu button
                            layersList={props.layersList}
                            updateOlLayerState={props.updateOlLayerState}
                            olLayerState={props.olLayerState}
                        />
                    </Grid>
                    { (bodyIsOpen) ?
                        <Grid item xs={12} sx={sxStyles.bodyContainer}>
                          <div style={classes.layerDivider}></div>
                            <MenuItemBody>
                                {props.children}
                            </MenuItemBody>
                        </Grid> : null
                    }
                </Grid>
                { (!bodyIsOpen) ? <div style={classes.layerDivider}></div> : null }
            </div>
        : null
    );
}

export default LayerMenuItem;