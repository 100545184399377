import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery, Button, IconButton, Collapse, Paper, Grid,
        Typography, CircularProgress, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import LayerSearchSubmenu from './layer-search-submenu';
import WWALayerMenuItem from './layer-menu-items/wwa.js';
import NDFDLayerMenuItem from './layer-menu-items/ndfd.js';
import S111LayerMenuItem from './layer-menu-items/ofs_sfc_currents.js';
import NBSLayerMenuItem from './layer-menu-items/nbs.js';
import MRMSLayerMenuItem from './layer-menu-items/mrms.js';
import STOFSLayerMenuItem from './layer-menu-items/stofs.js';
import SSTLayerMenuItem from './layer-menu-items/sst.js';
import SatelliteLayerMenuItem from './layer-menu-items/satellite.js';
import TropicalCyclonesLayerMenuItem from './layer-menu-items/tropical-cyclones.js';
import MRMSQPELayerMenuItem from './layer-menu-items/mrms-qpe.js';
import NauticalChartsLayerMenuItem from './layer-menu-items/nautical-charts.js';
import FedAgencyBoundariesLayerMenuItem from './layer-menu-items/federal-agency-boundaries.js';
import ZoneForecastsLayerMenuItem from './layer-menu-items/zone-forecasts.js';
import S100LayerMenuItem from './layer-menu-items/s100.js';
import PathogenLayerMenuItem from './layer-menu-items/pathogen.js';
import LightningDensityLayerMenuItem from './layer-menu-items/ltng_den.js';
import ConvectiveLayerMenuItem from './layer-menu-items/convective.js';
import SurfaceObsLayerMenuItem from './layer-menu-items/surface_obs.js';
import GenericLayerMenuItem from './layer-menu-items/generic.js';
import { LAYER_MENU_ITEM_DISPLAY_ORDER } from '../../config.js';

//const StyledTableCell = styled(TableCell)(({ theme }) => ({
//  [`&.${tableCellClasses.head}`]: {
//    backgroundColor: theme.palette.primary.main,
//    color: theme.palette.common.white,
//  },
//}));
//
//const StyledTableRow = styled(TableRow)(({ theme }) => ({
//  '&:nth-of-type(odd)': {
//    backgroundColor: theme.palette.action.hover,
//  },
//  // hide last border
//  '&:last-child td, &:last-child th': {
//    border: 0,
//  },
//}));

// Temporary URL for wmts (This value is used for link and copy buttons for bluetopo)
const BLUETOPO_WMTS_URL = "https://nowcoast.noaa.gov/geoserver/gwc/service/wmts?REQUEST=GetCapabilities";
const BLUETOPO_SERVICE_GUIDE =
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="caption" color="primary.main"><b>Add WMTS to QGIS</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontSize:10}} align="left"><li>In the <b>Browser Tab </b> > Right Click <b>WMS/WMTS > New Connection </b></li></Typography>
          <Typography sx={{fontSize:10}} align="left"><li>In <b>'Create a new WMS connection'</b> dialogue box enter a <b>Name</b> for the connection and <b>copy/paste the URL</b> from above into <b>URL</b> prompt</li></Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="caption" color="primary.main"><b>Add WMTS to ArcGIS Pro</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  sx={{fontSize:10}} align="left">
            <li>Select the <b>Insert tab</b>, click <b>Connections > Add Connection > New WMTS Server</b></li>
            <li><b>Copy/Paste</b> the <b>URL</b> from above into the text box</li>
            <li>After connecting to a WMTS server, a service connection is shown in the <b>Server folder</b> in the <b>Catalog</b> pane</li>
            <li><b>Drag</b> one of the following BlueTopo layers in the list into the map <b>(BlueTopo, BlueTopo Relief, or BlueTopo Tile Scheme)</b></li>
            <li>In the <b>Map Contents</b> pane <b>Right Click</b> the BlueTopo Bathymetric Surface layer to access layer properties</li>
            <li>In <b>Layer Properties > WMTS > Select Style (nbs_elevation, nbs_uncertainty, nbs_contributor)</b></li>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>;


const sxStyles = {
    menuItemsContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    menuContainer : {
        display: 'block',
        position: 'absolute',
        top: '3.5rem',
        right: '0rem',
        width: '21em',
        backgroundColor: '#fff',
    },
    layerMenuOpenButton : {
        backgroundColor: '#ffffff',
        color: 'primary.main',
        '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
        },
        borderRadius: 25,
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.5)', // attempted to match elevation 3 Paper
    },
    innerButtonContainer: {
        width: '100%',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
    },
    layerMenuButton : {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'primary.main',
        backgroundColor: '#fff',
        color: 'primary.main',
        '&:hover': {
            backgroundColor: '#b3cadf',
        },
        margin: '0.75em 0em 0.75em 1em',
    },
    topCategory: {
        backgroundColor: '#ffffff',
        '& *' : {
            color: 'primary.main',
            fontWeight: 'bold'
        }
    },
    secondLevelCategory: {
        backgroundColor: '#ffffff',
        '& *' : {
            color: 'primary.main',
        },
    },
    thirdLevelCategory: {
        backgroundColor: '#f5f5f5',
        paddingLeft: 40
    },
    linkButtonsContainer: {
        border: 'solid 1px',
        borderColor: 'primary.main',
        borderRadius: 1,
    },
    linkButton: {
        color: 'primary.main',
        '&:hover': {
            color: '#333',
            cursor: 'pointer',
        },
    },
    capabilitiesLinks: {
        color: 'primary.main',
        textDecoration: 'none',
        '&:hover': {
            color: '#333',
        },
        '&:visited': {
            color: 'primary.main'
        }
    },
};

// Define JSS styles for non-mui components
const classes = {
    layerDivider: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginLeft: 10,
        marginRight: 10,
    },
};

const IconLabelTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '65%',
        '& *' : {
            color: theme.palette.primary.light,
        }
    },
}));

/**
* Wrapper for conditionally rendering a tooltip only at cellphone size
*/
function CellPhoneToolTip(props) {
    const theme = useTheme();
    if (useMediaQuery(theme.breakpoints.only('xs'))) {
        return(
            <IconLabelTooltip arrow title="LAYERS" placement="top">
                {props.children}
            </IconLabelTooltip>
        );
    }
    return(
        <>
            {props.children}
        </>
    );
}

/**
* LayerMenuOpenButton: Button to open layer menu (sits above menu)
*
* @prop (bool) layerMenuOn - true if layer menu is open
* @prop (func) toggleLayerMenu - callback for handling layer menu toggle
*/
function LayerMenuOpenButton(props) {
    const theme = useTheme();
    const buttonContent = <><LayersIcon/><Typography variant="caption" style={{fontSize: useMediaQuery(theme.breakpoints.down('xs')) ? "75%" : ""}}>{useMediaQuery(theme.breakpoints.only('xs')) ? '' : 'Layers'}</Typography></>;
    const cellphone = (useMediaQuery(theme.breakpoints.only('xs')));

    return (
        <CellPhoneToolTip>
            { (cellphone) ?
                <IconButton size="small"
                    onClick={props.toggleLayerMenu}
                    sx={sxStyles.layerMenuOpenButton}
                    style={{
                        backgroundColor: props.layerMenuOn ? theme.palette.primary.main : "",
                        color: props.layerMenuOn ? theme.palette.primary.contrastText: ""
                    }}
                >
                    {buttonContent}
                </IconButton>
                :
                <Button size="small"
                    onClick={props.toggleLayerMenu}
                    sx={sxStyles.layerMenuOpenButton}
                    style={{
                        backgroundColor: props.layerMenuOn ? theme.palette.primary.main : "",
                        color: props.layerMenuOn ? theme.palette.primary.contrastText: ""
                    }}
                >
                    {buttonContent}
                </Button>
            }
        </CellPhoneToolTip>
    );
}

/**
* ActiveLayersButton: button that activates active layers filter (sits in layer menu)
* (MAP Button)
* @prop (str) currentSubmenu - currently selected submenu ("active", "all", "add")
* @prop (func) setCurrentSubmenu - set state of currently selected submenu ("active", "all", "add")
*/
function ActiveLayersButton(props) {
    const theme = useTheme();
    return (
        <Button size="small"
            sx={sxStyles.layerMenuButton}
            style={(props.currentSubmenu === "active") ? {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText} : {}}
            onClick={(e) => {props.setCurrentSubmenu("active")}}
        >
            <LayersIcon fontSize="small"/><Typography variant="button">Map</Typography>
        </Button>
    );
}

/**
* ClearLayersButton: button that removes all active layers (sits in layer menu)
*
* @prop (func) setActiveLayers - setter for active layers (takes an obj like layerToggles)
* @prop (obj) layerToggles - object mapping products to their on state (true/false)
*/
function ClearLayersButton(props){
    //const theme = useTheme();

    let activeLayersUpdate = {};
    for (let layer in props.layerToggles) {
        activeLayersUpdate[layer] = false;
    }

    return (
        <Button size="small"
            sx={sxStyles.layerMenuButton}
            onClick={(e) => {props.setActiveLayers(activeLayersUpdate);props.updateLayerToggles(activeLayersUpdate);}}
        >
            <LayersClearIcon fontSize="small"/><Typography variant="button">Clear</Typography>
        </Button>
    );
}

/**
* AllLayersButton: button that activates active layers filter (sits in layer menu)
* (ADD Button)
* @prop (str) currentSubmenu - currently selected submenu ("active", "all", "add")
* @prop (func) setCurrentSubmenu - set state of currently selected submenu ("active", "all", "add")
*
* NOTE: Currently displays "Add" and AddLayersButton is not used, since user-defined/custom
* layer functionality is not fully implemented. So until then we will display just the two
* buttons with out old naming scheme (layers currently on the "map" and layers to "add" to the map)
*/
function AllLayersButton(props){
    const theme = useTheme();
    return (
        <Button size="small"
            sx={sxStyles.layerMenuButton}
            style={(props.currentSubmenu === "all") ? {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText} : {}}
            onClick={(e) => {props.setCurrentSubmenu("all");}}
        >
            <LayersIcon fontSize="small"/><Typography variant="button">Add</Typography>
        </Button>
    );
}

/**
* AddLayersButton: button that activates menu for adding custom layers (Currently unused. Intended for user added layers)
*
* @prop (str) currentSubmenu - currently selected submenu ("active", "all", "add")
* @prop (func) setCurrentSubmenu - set state of currently selected submenu ("active", "all", "add")
*/
//function AddLayersButton(props){
//    const theme = useTheme();
//    return (
//        <Button size="small"
//            sx={sxStyles.layerMenuButton}
//            style={(props.currentSubmenu === "add") ? {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText} : {}}
//            onClick={(e) => {props.setCurrentSubmenu("add")}}
//        >
//            <LayersIcon fontSize="small"/><Typography variant="button">Add</Typography>
//        </Button>
//    );
//}

/**
* CapUrlsMenuContent: Component which displays menu content of capabilities urls in layer options submenu
*
*   @prop (obj) productInfo - obj containing info/metadata about a product (keywords, service urls)
*       - This must be specific to the product that this component is rendered for (ie. not the whole state obj)
*   @prop (bool) isExternal - (Optional) pass true to show external service header, else defaults to nowcoast WMS header
*/
export function CapUrlsMenuContent(props) {
    const theme = useTheme();
    //currently only supporting wmsCapUrls
    if (!props.productInfo || !props.productInfo.wmsCapUrls ) {
        return <CircularProgress />;
    }

    const wmsUrls = props.productInfo.wmsCapUrls.map((urlInfo, i) => {
        return(
            <Grid container sx={sxStyles.linkButtonsContainer} key={i} alignItems="center">
                <IconLabelTooltip arrow placement="bottom" title="Copy">
                    <Grid item xs={9}
                        onClick={() => navigator.clipboard.writeText(urlInfo.url)}
                        sx={{...sxStyles.linkButton, borderRight: 'solid 1px' + theme.palette.primary.main, paddingRight: '0.5em'}}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={3}>
                                <ContentCopyIcon size="small" fontSize="small"
                                    sx={{mr: '0.5em', mt: '0.2em'}}/>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="caption" size="small" align="left">{urlInfo.title}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </IconLabelTooltip>
                <IconLabelTooltip arrow placement="bottom" title="Capabilities">
                    <Grid item xs={3} sx={sxStyles.linkButton} >
                        <a href={urlInfo.url} target="_blank" rel="noopener noreferrer">
                            <OpenInBrowserIcon color="primary" sx={{...sxStyles.capabilitiesLinks, marginTop: '0.2em'}}/>
                        </a>
                    </Grid>
                </IconLabelTooltip>
            </Grid>
        );
    });

    // Temporary alternative return for bluetopo containing additional instructions
    // NOTE: This is intended to be bluetopo only but if any other service tries to advertise wmts then this branch will be entered
    // ToDo: Address issue above if/when we add more wmts services
    if (props.productInfo.wmsCapUrls[0].url && props.productInfo.wmsCapUrls[0].url.includes("gwc/service/wmts")) {
        return(
            <div>
                <Typography color="primary" variant="subtitle2" align="center">nowCOAST OGC Map Services (WMTS)</Typography>
                <br />
                {wmsUrls}
                <Grid container alignItems="center" sx={{pt: '10px'}}>
                    {BLUETOPO_SERVICE_GUIDE}
                </Grid>
            </div>
        );
    } else if (props.isExternal) {
        return(
            <div>
                <Typography color="primary" variant="subtitle2" align="center">External Services</Typography>
                <br />
                {wmsUrls}
            </div>
        );
    }

    return(
        <div>
            <Typography color="primary" variant="subtitle2" align="center">nowCOAST OGC Map Services (WMS)</Typography>
            <br />
            {wmsUrls}
        </div>
    );
}

// REFACTORING POSSIBILITY:
// The product specific layer-menu-item files (mrms.js, nbs.js, etc.) Are largely pass-throughs which might be removed
// if we want. They consist primarily of renders of a generic layer menu item component but with product specific inputs
// They are rendered below in RenderLayerMenuItems. However, RenderLayerMenuItems already has significant product-specific
// hard-coding (like breaking off just the product related material from top level state objects). Since we are already
// hard-coding here we may want to push all the other hard-coded stuff in the product-specific layer menu item components
// into this file. It would bloat this file significantly but we could essentially delete files like mrms.js. An example
// of how this would go is offered by CapUrlsMenuContent which was implemented with this kind of refactoring in mind.
// The biggest caveat is that many of the product specific files like ndfd.js, nbs.js, etc. also define product specific
// components for their layer controls which cannot be abstracted. Those would have to remain somewhere (probably still
// separate files that can be imported into this one and rendered below as needed). Carefully consider these tradeoffs.

/**
* RenderLayerMenuItem: Renders the layer menu item associated with layer_group name (layerToggles keys) passed into it
*
*   @prop (string) layerName - name of layer_group/product's menu item to return (must match a key in props.layerToggles (top level key from LAYERS in config.js))
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerIsActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setLayerIsActive - callback for setting layerIsActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (string) label - label that will appear on the layer control
*   @prop (bool) bodyIsOpen - true if body menu is open
*   @prop (func) setBodyIsOpen - callback for changing value of bodyIsOpen
*   @prop (list) layersList - Optional - list of strings containing dynamic layer names turned on for particular layer
*   @prop (obj) customLayerInfo - State for managing custom layers
*   @prop (func) updateCustomLayerInfo - Callback for managing custom layers
*   @prop (str) currentSubmenu - Name or submenu being displayed ("all", "active", "add") Affects the way we choose layers to display.
*   @prop (obj) activeLayers - Map layer_group/product names to true/false indicating if layer is active
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (bool) greatLakesChecked - State used by OFS/S111 menu item component
*   @prop (func) setGreatLakesChecked - State used by OFS/S111 menu item component
*   @prop (bool) coastalOceanChecked - State used by OFS/S111 menu item component
*   @prop (func) setCoastalOceanChecked - State used by OFS/S111 menu item component
*   @prop (bool) globalOceanChecked - State used by OFS/S111 menu item component
*   @prop (func) setGlobalOceanChecked - State used by OFS/S111 menu item component
*   @prop (obj) greatLakesControlStates - State used by OFS/S111 menu item component
*   @prop (func) setGreatLakesControlStates - State used by OFS/S111 menu item component
*   @prop (obj) coastalOceanControlStates - State used by OFS/S111 menu item component
*   @prop (func) setCoastalOceanControlStates - State used by OFS/S111 menu item component
*   @prop (obj) productInfo - obj containing info/metadata about a product (keywords, service urls)
*/
function RenderLayerMenuItem(props) {
    const handleSetActiveLayers = (layerName) => {
        if (props.activeLayers && props.setActiveLayers) {
            return((isActive) => {
                props.setActiveLayers({...props.activeLayers, [layerName]: isActive});
            });
        }else{
            return(() => null);
        }
    };

    // Custom layer functionality currently not in use.
    if (props.customLayerInfo && Object.keys(props.customLayerInfo).includes(props.layerName)) {
        // Function for updating layer opacities of custom layers in a way that
        // mimics the original updateLayerOpacities function (so that existing
        // menu item components can work with customLayerInfo state
        // (possible alternative involves mixing custom layer state with regular layer state,
        // ie. adding custom layers to layerToggles, layerOpacities, etc)
        const customLayerOpacityUpdate = (update) => {
            // Must take update in form {layerName: opacity} and update customLayerInfo properly
            const layerName = Object.keys(update)[0];
            const newOpacity = update[layerName];
            props.updateCustomLayerInfo({
                [layerName]: {
                    ...props.customLayerInfo[layerName],
                    opacity: newOpacity,
                }
            });
        };

        // Same as above but for layer toggles
        const customLayerToggleUpdate = (update) => {
            // Must take update in form {layerName: true/false} and update customLayerInfo properly
            // But true/false is from !props.layerToggles[props.layerName] so you need to spoof that as well
            const layerName = Object.keys(update)[0];
            const newToggleState = update[layerName];

            props.updateCustomLayerInfo({
                [layerName]: {
                    ...props.customLayerInfo[layerName],
                    on: newToggleState,
                }
            });
        };

        return(
            <GenericLayerMenuItem
                layerName={props.layerName}
                layerToggles={{[props.layerName]: props.customLayerInfo[props.layerName].on}}
                updateLayerToggles={customLayerToggleUpdate}
                layerInitialized={true}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                layerActive={(props.activeLayers) ? props.activeLayers[props.layerName] : false}
                setLayerActive={handleSetActiveLayers(props.layerName)}
                opacity={props.customLayerInfo[props.layerName].opacity}
                updateLayerOpacities={customLayerOpacityUpdate}
            />
        );
    }

    if (props.layerName === "ndfd") {
        return(
            <NDFDLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={props.initializedCaps.ndfd}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                ndfdActive={(props.activeLayers) ? props.activeLayers.ndfd : false}
                setNdfdActive={handleSetActiveLayers("ndfd")}
                opacity={props.layerOpacities.ndfd}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.ndfd}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.ndfd}/>}
            />
        );
    } else if (props.layerName === "s111") {
        return(
            <S111LayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={props.initializedCaps.s111}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                s111Active={(props.activeLayers) ? props.activeLayers.s111 : false}
                setS111Active={handleSetActiveLayers("s111")}
                opacity={props.layerOpacities.s111}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.s111}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                greatLakesChecked={props.greatLakesChecked}
                setGreatLakesChecked={props.setGreatLakesChecked}
                coastalOceanChecked={props.coastalOceanChecked}
                setCoastalOceanChecked={props.setCoastalOceanChecked}
                globalOceanChecked={props.globalOceanChecked}
                setGlobalOceanChecked={props.setGlobalOceanChecked}
                greatLakesControlStates={props.greatLakesControlStates}
                setGreatLakesControlStates={props.setGreatLakesControlStates}
                coastalOceanControlStates={props.coastalOceanControlStates}
                setCoastalOceanControlStates={props.setCoastalOceanControlStates}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.s111}/>}
            />
        );
    } else if(props.layerName === "convective_outlooks") {
        return(
            <ConvectiveLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={true}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                convectiveActive={(props.activeLayers) ? props.activeLayers.convective_outlooks : false}
                setConvectiveActive={handleSetActiveLayers("convective_outlooks")}
                opacity={props.layerOpacities.convective_outlooks}
                updateLayerOpacities={props.updateLayerOpacities}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.convective_outlooks}/>}
            />
        );
    } else if (props.layerName === "surface_obs") {
        return(
            <SurfaceObsLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                productActive={(props.activeLayers) ? props.activeLayers.surface_obs : false}
                setProductActive={handleSetActiveLayers("surface_obs")}
                opacity={props.layerOpacities.surface_obs}
                updateLayerOpacities={props.updateLayerOpacities}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                layerInitialized={true}
                onlyDisplayActive={(props.currentSubmenu === "active")}
            />
        );
    } else if(props.layerName === "nbs") {
        return(
            <NBSLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={props.initializedCaps.nbs}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                nbsActive={(props.activeLayers) ? props.activeLayers.nbs : false}
                setNbsActive={handleSetActiveLayers("nbs")}
                opacity={props.layerOpacities.nbs}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.nbs}
                activeStylesList={props.olLayerState.bathymetry.stylesParam}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                capUrlsContent={(props.productInfo.nbs && props.productInfo.nbs.wmsCapUrls) ? <CapUrlsMenuContent productInfo={{"wmsCapUrls": [{...props.productInfo.nbs.wmsCapUrls[0], url: BLUETOPO_WMTS_URL}]}}/> : null}
            />
        );
    } else if(props.layerName === "mrms") {
        return(
            <MRMSLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={props.initializedCaps.mrms}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                mrmsActive={(props.activeLayers) ? props.activeLayers.mrms : false}
                setMrmsActive={handleSetActiveLayers("mrms")}
                opacity={props.layerOpacities.mrms}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.mrms}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.mrms}/>}
            />
        );
    } else if(props.layerName === "mrms_qpe") {
        return(
            <MRMSQPELayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={true}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                qpeActive={(props.activeLayers) ? props.activeLayers.mrms_qpe : false}
                setQpeActive={handleSetActiveLayers("mrms_qpe")}
                opacity={props.layerOpacities.mrms_qpe}
                updateLayerOpacities={props.updateLayerOpacities}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                styleInfo={props.styleInfo.mrms_qpe}
                capUrlsContent={<CapUrlsMenuContent isExternal={true} productInfo={{
                    ...props.productInfo.mrms_qpe,
                    wmsCapUrls: [{
                        url: "https://mapservices.weather.noaa.gov/raster/rest/services/obs/mrms_qpe/ImageServer",
                        title: "MRMS QPE ArcGIS"
                    }]
                }}/>}
            />
        );
    } else if(props.layerName === "wwa") {
        return(
            <WWALayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={true} // wwa does not need to wait for initialization from get capabilities
                onlyDisplayActive={(props.currentSubmenu === "active")}
                wwaActive={(props.activeLayers) ? props.activeLayers.wwa : false}
                setWwaActive={handleSetActiveLayers("wwa")}
                opacity={props.layerOpacities.wwa}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.wwa}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.wwa}/>}
            />
        );
    } else if(props.layerName === "zone_forecasts") {
        return(
            <ZoneForecastsLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={true}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                productActive={(props.activeLayers) ? props.activeLayers.zone_forecasts : false}
                setProductActive={handleSetActiveLayers("zone_forecasts")}
                opacity={props.layerOpacities.zone_forecasts}
                updateLayerOpacities={props.updateLayerOpacities}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
            />
        );
    } else if(props.layerName === "stofs") {
        return(
            <STOFSLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={props.initializedCaps.stofs}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                stofsActive={(props.activeLayers) ? props.activeLayers.stofs : false}
                setStofsActive={handleSetActiveLayers("stofs")}
                opacity={props.layerOpacities.stofs}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.stofs}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.stofs}/>}
            />
        );
    }  else if(props.layerName === "sst") {
        return(
            <SSTLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={props.initializedCaps.sst}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                sstActive={(props.activeLayers) ? props.activeLayers.sst : false}
                setSstActive={handleSetActiveLayers("sst")}
                opacity={props.layerOpacities.sst}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.sst}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.sst}/>}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
            />
        );
    } else if(props.layerName === "satellite") {
        return(
            <SatelliteLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={props.initializedCaps.satellite}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                satelliteActive={(props.activeLayers) ? props.activeLayers.satellite : false}
                setSatelliteActive={handleSetActiveLayers("satellite")}
                opacity={props.layerOpacities.satellite}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.satellite}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.satellite}/>}
            />
        );
    } else if(props.layerName === "tropical_cyclones") {
        return(
            <TropicalCyclonesLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                olLayerState={props.olLayerState} //layersList={props.olLayerState.tropical_cyclones.layersParam}
                layerInitialized={props.initializedCaps.tropical_cyclones}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                cycloneActive={(props.activeLayers) ? props.activeLayers.tropical_cyclones : false}
                setCycloneActive={handleSetActiveLayers("tropical_cyclones")}
                opacity={props.layerOpacities.tropical_cyclones}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo} //styleInfo={props.styleInfo.tropical_cyclones}
                updateOlLayerState={props.updateOlLayerState}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.tropical_cyclones}/>}
            />
        );
    } else if(props.layerName === "nautical_charts") {
        return(
            <NauticalChartsLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={true}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                nauticalChartsActive={(props.activeLayers) ? props.activeLayers.nautical_charts : false}
                setNauticalChartsActive={handleSetActiveLayers("nautical_charts")}
                opacity={props.layerOpacities.nautical_charts}
                updateLayerOpacities={props.updateLayerOpacities}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
            />
        );
    } else if(props.layerName === "federal_agency_boundaries") {
        return(
            <FedAgencyBoundariesLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={true}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                productActive={(props.activeLayers) ? props.activeLayers.federal_agency_boundaries : false}
                setProductActive={handleSetActiveLayers("federal_agency_boundaries")}
                opacity={props.layerOpacities.federal_agency_boundaries}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.federal_agency_boundaries}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.federal_agency_boundaries}/>}
            />
        );
    } else if(props.layerName === "s100") {
        return(
            <S100LayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={true}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                productActive={(props.activeLayers) ? props.activeLayers.s100 : false}
                setProductActive={handleSetActiveLayers("s100")}
                opacity={props.layerOpacities.s100}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.s100}
                activeStylesList={props.olLayerState.s100_approach_coverage.stylesParam}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
            />
        );
    } else if (props.layerName === "pathogen") {
        return(
            <PathogenLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={props.initializedCaps.pathogen}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                pathogenActive={(props.activeLayers) ? props.activeLayers.pathogen : false}
                setPathogenActive={handleSetActiveLayers("pathogen")}
                opacity={props.layerOpacities.pathogen}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.pathogen}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.pathogen}/>}
            />
        );
    } else if (props.layerName === "ltng_den" ){
        return(
            <LightningDensityLayerMenuItem
                layerToggles = {props.layerToggles}
                updateLayerToggles={props.updateLayerToggles}
                layerInitialized={props.initializedCaps.ltng_den}
                onlyDisplayActive={(props.currentSubmenu === "active")}
                lightningDensityActive={(props.activeLayers) ? props.activeLayers.ltng_den : false}
                setLightningDensityActive={handleSetActiveLayers("ltng_den")}
                opacity={props.layerOpacities.ltng_den}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo.ltng_den}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                capUrlsContent={<CapUrlsMenuContent productInfo={props.productInfo.ltng_den}/>}
            />
        );
    } else {
        return(null);
    }
}


/**
* LayerMenuContent: All content/state/functionality within the layer menu
*
* @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
* @prop (func) updateLayerToggles - callback for updating layerToggles
* @prop (func) toggleLayerMenu - callback for handling layer menu toggle
* @prop (obj) initializedCaps - maps layer names that rely on getCapabilities to true if the capabilities are done (ie. layer is ready)
* @prop (obj) layerOpacities - maps layer groups to their opacity value (0-100)
* @prop (func) updateLayerOpacities - callback func for updating opacities
* @prop (func) updateCustomLayerInfo - Callback for setting customLayerInfo
* @prop (obj) customLayerInfo - Contains all state for managing custom/user-added layers
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
* @prop (func) updateOlLayerState - callback for updating olLayerState
* @prop (obj) productInfo - obj containing info/metadata about a product (keywords, service urls)
*
*/
function LayerMenuContent(props) {
    const theme = useTheme();

    // Store currently selected submenu ("active", "all", "add")
    const [currentSubmenu, setCurrentSubmenu] = useState("active");

    // Store list of layer menu items to display
    const [displayedMenuItems, setDisplayedMenuItems] = useState([]);

    // Store list of layer menu items to display when search filter is active
    const [filteredMenuItems, setFilteredMenuItems] = useState([]);

    // Store Active Layer Status for each layer in use ie. {<layer_group>: <true/false>}
    const [activeLayers, setActiveLayers] = useState({...props.layerToggles, nbs: true, s100: true} ); // on page load nbs & s100 should be active but not on, so not copying it from layerToggles which would have it set to false here

    // Store the value of the current category filter
    // (must be string of: "all", "ocean", "future", "hazard", "present", "overlays", "guidance", "custom")
    const [categoryFilter, setCategoryFilter] = useState("all");

//    // make a ref for the grid item containing the top submenu
//    // (Formerly used for to measure height of search menu and to adjust overall menu height
//    // based on that value since it may or may not exist)
//    const subMenuContainer = useRef();

    // Store an em measure (int) to dictate current height of the search menu between shown and hidden states
    const [subMenuContainerHeight, setSubMenuContainerHeight] = useState(11.5);

    // State for OFS menu item component. It must be store up here because it is lost if that component
    // unmounts (this is closest parent that does not unmount) (Also a great example of why we want global state)
    const [greatLakesChecked, setGreatLakesChecked] = useState(false);
    const [coastalOceanChecked, setCoastalOceanChecked] = useState(false);
    const [globalOceanChecked, setGlobalOceanChecked] = useState(true);

    // NOTE: Keys below are hard-coded to match OL Layer names in config.js and keys in olLayerState object
    const [greatLakesControlStates, setGreatLakesControlStates] = useState({
        leofs_sfc_currents: false,
        lmhofs_sfc_currents: false,
        loofs_sfc_currents: false,
        lsofs_sfc_currents: false,
    });

    const [coastalOceanControlStates, setCoastalOceanControlStates] = useState({
        cbofs_sfc_currents: false,
        dbofs_sfc_currents: false,
        gomofs_sfc_currents: false,
        ngofs_sfc_currents: false,
        nyofs_sfc_currents: false,
        sfbofs_sfc_currents: false,
        tbofs_sfc_currents: false,
        wcofs_sfc_currents: false,
    });

//    // Saving this: a method for merging customLayerInfo updates with layerToggles and layerOpacities in case
//    // we need to manage those layers via those objects. Currently we have special callbacks that allow us to
//    // have the same menu item components manipulate customLayerInfo instead, which is also what is hooked
//    // into the custom layers in the layer-manager component
//    const prevCustomLayers = useRef(Object.keys(props.customLayerInfo));
//    // save the previous custom layer list
//    // everytime custom layer info changes, if the length is different,
//    // do a comparison to see what keys were added or removed
//    // then add/remove that key from layerToggles, layerOpacities
//
//    if (prevCustomLayers.current.length !== Object.keys(props.customLayerInfo).length) {
//        // Length has changed so assume we need to add or delete something
//        for (const layerName in props.customLayerInfo) {
//            if (!prevCustomLayers.current.includes(layerName)) {
//                // Current list has one that prev doesnt so add it
//                props.updateLayerToggles({[layerName]: props.customLayerInfo[layerName].on});
//                props.updateLayerOpacities({[layerName]: props.customLayerInfo[layerName].opacity});
//            }
//        }
//
//        for (const layerName in prevCustomLayers.current) {
//            if (!Object.keys(props.customLayerInfo).includes(layerName)) {
//                // Prev list has one that current doesnt so delete it
//                props.updateLayerToggles(layerName, true);
//                //props.updateLayerOpacities(layerName, true); IF IT WORKS FOR LAYERTOGGLES ADD SUPPORT FOR THIS TO THE OPACITIES CALLBACK
//            }
//        }
//    }

    // Update displayedMenuItems when displayed layer state changes (activeLayers, searchedLayers, userAddedLayers)
    useEffect(() => {
        if (currentSubmenu && activeLayers) {
            // Sort layer menu items based on defined priority for active layers (LAYER_MENU_ITEM_DISPLAY_ORDER)
            let prioritizedArray = [];
            let unprioritizedArray = [];
            for (const layerName of LAYER_MENU_ITEM_DISPLAY_ORDER){
                // Update activeLayers to add any layers that have been turned on, but not yet set to active
                // This allows us to have dependent layers (toggled on when other layers are toggled on) set to active
                if(props.layerToggles[layerName] && !activeLayers[layerName]) {
                    setActiveLayers({...activeLayers, [layerName]: true})
                }
                if (currentSubmenu === "active") {
                    if (activeLayers && activeLayers[layerName]) {
                        prioritizedArray.push(layerName);
                    }
                    continue;
                } else {
                    // For the Add submenu, use filteredMenuItems to persist category filter
                    if(filteredMenuItems.includes(layerName)) {
                        prioritizedArray.push(layerName);
                    }
                }
            }
            for (const layerName in activeLayers) {
                if (currentSubmenu === "active") {
                    if (activeLayers[layerName] && !prioritizedArray.includes(layerName)) {
                        unprioritizedArray.push(layerName);
                    }
                    continue;
                } else if (!prioritizedArray.includes(layerName) && filteredMenuItems.includes(layerName)) {
                    unprioritizedArray.push(layerName);
                }
            }
            setDisplayedMenuItems(prioritizedArray.concat(unprioritizedArray));
        }
    }, [setDisplayedMenuItems, currentSubmenu, activeLayers, props.layerToggles, categoryFilter, filteredMenuItems])


    let submenuComponents = null;
    if(currentSubmenu === "active") {
        submenuComponents = null; //so delete this line dumb dumb - and branch probly
    } else if(currentSubmenu === "all") {
        submenuComponents = <>
            <div style={classes.layerDivider} ></div>
            <LayerSearchSubmenu
                categoryFilter={categoryFilter}
                setCategoryFilter={setCategoryFilter}
                setFilteredMenuItems={setFilteredMenuItems}
                layerToggles={props.layerToggles}
                setSubMenuContainerHeight={setSubMenuContainerHeight}
                productInfo={props.productInfo}
            />
        </>;
    } else if(currentSubmenu === "add") {
        submenuComponents = <h2>Add Custom Layer Menu Here</h2>;
    }

    // memoize menuItemComponents if it seems slow to calculate on every render
    let menuItemComponents = displayedMenuItems.map((itemName) => {
        return(
            <RenderLayerMenuItem
                key={itemName}
                layerName={itemName}
                updateLayerToggles={props.updateLayerToggles}
                layerToggles={props.layerToggles}
                initializedCaps={props.initializedCaps}
                currentSubmenu={currentSubmenu}
                layerOpacities={props.layerOpacities}
                updateLayerOpacities={props.updateLayerOpacities}
                styleInfo={props.styleInfo}
                customLayerInfo={props.customLayerInfo}
                updateCustomLayerInfo={props.updateCustomLayerInfo}
                activeLayers={activeLayers}
                setActiveLayers={setActiveLayers}
                olLayerState={props.olLayerState}
                updateOlLayerState={props.updateOlLayerState}
                greatLakesChecked={greatLakesChecked}
                setGreatLakesChecked={setGreatLakesChecked}
                coastalOceanChecked={coastalOceanChecked}
                setCoastalOceanChecked={setCoastalOceanChecked}
                globalOceanChecked={globalOceanChecked}
                setGlobalOceanChecked={setGlobalOceanChecked}
                greatLakesControlStates={greatLakesControlStates}
                setGreatLakesControlStates={setGreatLakesControlStates}
                coastalOceanControlStates={coastalOceanControlStates}
                setCoastalOceanControlStates={setCoastalOceanControlStates}
                productInfo={props.productInfo}
            />
        );
    });

    // Note: Functionality to support Custom/User-Defined Layers is incomplete.
    // Currently code has been written to handle the additional custom layers
    // using customLayerInfo for state and layer-menu-items/generic.js for the menu
    // component. It is ready to enable and test with a predefined layer.
    // After that test is completed and issues worked out, the functionality needs
    // work on defining the interface for the layer with the user. So how to get file name
    // for a local geojson or what paramenters/request types to handle for remote url sources
    //
    // This component would be displayed after the other buttons below
    //<AddLayersButton style={{position:'relative',right:'0.5em'}}
    //    currentSubmenu={currentSubmenu}
    //    setCurrentSubmenu={setCurrentSubmenu}
    ///>

    // Control max height of menu item container at various screen sizes and various submenu sizes
    // (submenu meaning the portion of the layer menu with all/add buttons or search controls)
    let menuItemsContainerStyle = null;
    let timeSliderOffset = 12.5; // Subtracted from viewer height to make sure menu does not overlap timeslider

    if (useMediaQuery(theme.breakpoints.only('xl'))) {
        timeSliderOffset = 12.5; // Reduce this value to increase max size of menu
    }

    if (useMediaQuery(theme.breakpoints.only('lg'))) {
        timeSliderOffset = 15.5;
    }

    if (useMediaQuery(theme.breakpoints.only('md'))) {
        timeSliderOffset = 15.5;
    }

    if (useMediaQuery(theme.breakpoints.down('md'))) {
        timeSliderOffset = 15.75;
    }

    let totalOffset = timeSliderOffset;
    if (currentSubmenu !== "active") {
        totalOffset = subMenuContainerHeight + timeSliderOffset;
    }

    // Calculate max height of menu before having to scroll
    menuItemsContainerStyle = {
        maxHeight: 'calc((100vh - ' + totalOffset + 'em)'
    }

// Previous placement of subMenuContainer Ref (top grid item)
//            <Grid item xs={12} sx={sxStyles.innerButtonContainer} ref={subMenuContainer}>
    return (
        <Grid container>
            <Grid item xs={12} sx={sxStyles.innerButtonContainer} >
                <ActiveLayersButton style={{marginRight:'2em'}}
                    currentSubmenu={currentSubmenu}
                    setCurrentSubmenu={setCurrentSubmenu}
                />
                <AllLayersButton style={{position:'relative',right:'0.5em'}}
                    setCategoryFilter={setCategoryFilter}
                    currentSubmenu={currentSubmenu}
                    setCurrentSubmenu={setCurrentSubmenu}
                />
                <ClearLayersButton style={{position:'relative',left:'0.5em'}}
                    layerToggles={props.layerToggles}
                    updateLayerToggles={props.updateLayerToggles}
                    setActiveLayers={setActiveLayers}
                />
                <IconButton size="large" color="primary" onClick={() => {props.toggleLayerMenu(false)}} >
                    <CloseIcon />
                </IconButton>
                {submenuComponents}
            </Grid>
            <Grid item xs={12} sx={sxStyles.menuItemsContainer} style={menuItemsContainerStyle}>
                {
                    (displayedMenuItems.length !== 0) ? menuItemComponents : <Typography style={{margin:'50px 0px'}}>No layers found matching search criteria.</Typography>
                }
            </Grid>
        </Grid>
    );
}

/**
* LayerMenu: contains layer menu toggle button and container for layer menu itself
*
* @prop (func) setLegendMenuOn - callback for toggling legend menu which is mutually exclusive to layer menu
* @prop (bool) layerMenuOn - true if layer menu is on
* @prop (func) setLayerMenuOn - callback for toggling layer menu
* @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
* @prop (func) updateLayerToggles - callback for updating layerToggles
* @prop (obj) initializedCaps - maps layer names that rely on getCapabilities to true if the capabilities are done (ie. layer is ready)
* @prop (obj) layerOpacities - maps layer groups to their opacity value (0-100)
* @prop (func) updateLayerOpacities - callback func for updating opacities
* @prop (func) updateCustomLayerInfo - Callback for setting customLayerInfo
* @prop (obj) customLayerInfo - Contains all state for managing custom/user-added layers
* @prop (func) updateMapClickPopup - callback for updating mapClickPopupOn
* @prop (bool) mapClickPopupOn - true if the feature info popup is open, else false
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
* @prop (func) updateOlLayerState - callback for updating olLayerState
* @prop (obj) productInfo - obj containing info/metadata about a product (keywords, service urls)
*/
function LayerMenu(props) {
    const toggleLayerMenu = () => {
        const curState = props.layerMenuOn;
        if (!curState) {
            props.setLegendMenuOn(false);
        }
        // Turn off feature info popup if menu is going from false to true and feature info popup is on
        if (props.mapClickPopupOn && !curState) {
            props.updateMapClickPopup(false);
        }
        props.setLayerMenuOn(!curState);
    }

    return (
        <div >
            <LayerMenuOpenButton
                toggleLayerMenu={toggleLayerMenu}
                layerMenuOn={props.layerMenuOn}
            />
            <Collapse
                {...(props.layerMenuOn ? { timeout: 1000 } : {timeout: 0})}
                in={props.layerMenuOn}
                style={{transformOrigin:'top'}}
            >
                <Paper elevation={3} sx={sxStyles.menuContainer} >
                    <LayerMenuContent
                        toggleLayerMenu={toggleLayerMenu}
                        layerToggles = {props.layerToggles}
                        initializedCaps = {props.initializedCaps}
                        updateLayerToggles={props.updateLayerToggles}
                        layerOpacities={props.layerOpacities}
                        updateLayerOpacities={props.updateLayerOpacities}
                        styleInfo={props.styleInfo}
                        customLayerInfo={props.customLayerInfo}
                        updateCustomLayerInfo={props.updateCustomLayerInfo}
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                        productInfo={props.productInfo}
                    />
                </Paper>
            </Collapse>
        </div>
    );
}

export default LayerMenu;