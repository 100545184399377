import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import { CircularProgress, Typography } from '@mui/material';
import { getTimeZone, tooltipDateString, changeTimezoneGMT } from './utilities.js';

const sxStyles = {
    errorText: {
        fontStyle: "italic",
        marginTop: "10px",
        color: "red"
    }
}

const classes = {
    select: {
        'textAlign': 'center',
        'textAlignLast': 'center',

        'marginLeft': 'auto',
        'marginRight': 'auto',

        'display': 'flex',
        'justifyContent': 'center',
    },
};

/**
* Component for rendering time series graphs from COOPS tide stations for
* non-Great Lakes stations
*
* @prop (string) waterURL - string used to query water level observation data
* @prop (string) predURL - string used to query water level observation data
* @prop (obj) datums - json obj with available datums used for water level queries
**/
const WaterLevelChart = (props) => {

    // waterData & predData are of the form: {data: [...], datum: "..."}
    const [waterData, setWaterData] = useState({data: null, datum: null})
    const [waterError, setWaterError] = useState(null)
    const [predData, setPredData] = useState({data: null, datum: null})
    const [predError, setPredError] = useState(null)
    const [waterTries, setWaterTries] = useState(10)
    const ref = useRef()

    const datumsParsed = []
    for (var i = 0; i < props.datums.length; i++) {
        var datumObj = props.datums[i]
        var name = datumObj.name;
        var description = datumObj.description;

        if (name === "STND" || name === "MHHW" || name === "MHW" || name === "MTL" || name === "MSL" || name === "MLW" || name === "MLLW") {
            datumsParsed.push({
            id: name,
            name: name,
            description: description,
            });
        } else if (name === "NAVD88") {
            datumsParsed.push({
            id: "NAVD",
            name: "NAVD",
            description: description,
            });
        } else if (name === "CRD_OFFSET") {
            datumsParsed.push({
            id: "CRD",
            name: "CRD",
            description: description,
            });
        }
    }

    const getInitialState = () => {
        var value = "STND"
        for (var i = 0; i < props.datums.length; i++) {
            var datumObj = props.datums[i]
            var name = datumObj.name;
            if (name === "MLLW") {
                value = "MLLW";
            }

        }
        return value;
    };

    const [datumSelect, setDatum] = useState(getInitialState);

    const handleChange = (e) => {
        setDatum(e.target.value);
    };

    const datumOptions = <>
            <select onChange={handleChange} defaultValue={"Select Datum"} style={{marginTop: '.5em', ...classes.select}}>
                <option value="Select Datum" disabled hidden>Select Datum</option>
                {datumsParsed.map(datumList =>
                  <option key={datumList.name} value={datumList.name}>{datumList.name} - {datumList.description}</option>
                )};
            </select>
        </>

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // This useEffect uses props.waterURL to query the data
    // Relies on state from waterData, datumSelect, waterTries, and waterError
    useEffect(() => {
        async function getJSON(queryUrl, setWaterData, datumSelect, setDatum, waterTries, setWaterTries, setWaterError) {
            const urlParams = new URLSearchParams("?" + queryUrl.split('?')[1]);
            const station = urlParams.get('station')
            let plusDatum = queryUrl + '&datum=' + datumSelect
            let response = await fetch(plusDatum)
            let responseData = await response.text();
            let parsedData = JSON.parse(responseData);
            if (parsedData.hasOwnProperty('data') && parsedData.data.length !== 1) {
                const aData = parsedData.data
                const slimData = aData.map(({s, ...rest}) => {
                    return rest;
                });
                slimData.forEach((d) => {
                    d.t = changeTimezoneGMT(new Date(d.t), timeZone);
                    d.v = +parseFloat(d.v);
                });
                setWaterData({ data: slimData, datum: datumSelect })
            } else if (parsedData.hasOwnProperty('data') && parsedData.data.length === 1 && waterTries > 0) {
                await new Promise(r => setTimeout(r, 2000));
                setWaterTries(waterTries - 1)
            } else if (parsedData.hasOwnProperty('error')) {
                const errorMsg = parsedData.error.message
                setWaterError("No observation data for this station.")
            } else if (waterTries === 0) {
                setWaterError("Error when attempting to retrieve water observation data.")
            }
        }
        getJSON(props.waterURL, setWaterData, datumSelect, setDatum, waterTries, setWaterTries, setWaterError);

    }, [props.waterURL, setWaterData, datumSelect, setDatum, waterTries, setWaterTries, setWaterError])

    // This useEffect uses props.predURL to query the data
    // Relies on state from predData, datumSelect, and predError
    useEffect(() => {
        async function getJSON(queryUrl, setPredData, datumSelect, setPredError) {
            const urlParams = new URLSearchParams("?" + queryUrl.split('?')[1]);
            const station = urlParams.get('station')
            let plusDatum = queryUrl + '&datum=' + datumSelect
            let response = await fetch(plusDatum)
            let responseData = await response.text();
            let parsedData = JSON.parse(responseData);
            if (parsedData.hasOwnProperty('predictions') && parsedData.predictions.length !== 1) {
                const aData = parsedData.predictions
                const slimData = aData.map(({q, s, ...rest}) => {
                    return rest;
                });
                slimData.forEach((d) => {
                    d.t = changeTimezoneGMT(new Date(d.t), timeZone);
                    d.v = +parseFloat(d.v);
                });
                setPredData({ data: slimData, datum: datumSelect })
            } else if (parsedData.hasOwnProperty('error')) {
                const errorMsg = parsedData.error.message;
                setPredError("No prediction data available for this station.")
            }
        }
        getJSON(props.predURL, setPredData, datumSelect, setPredError);

    }, [props.predURL, setPredData, datumSelect, setPredError])

    // The useEffect defines the time series object
    useEffect(() => {
        // When data exists for both predictions and observations, do not render graph yet if the datums do not match
        // (non-matching datums likely indicates that we are still waiting on a request for other set of data)
        if (predData.data !== null && waterData.data !== null) {
            if (predData.datum !== waterData.datum) {
                return
            }
        }

        if (predData.data !== null || waterData.data !== null) {

            // set the dimensions and margins of the graph
            var margin = { top: 10, right: 10, bottom: 50, left: 25 },
            width = 500 - margin.left - margin.right,
            height = 300 - margin.top - margin.bottom;

            if (waterData.data !== null && predData.data !== null) {
                predData.data.forEach((d) => {
                    if (!(d.t in waterData.data)){
                        waterData.data.push({t: d.t, v: NaN});
                    }
                });
            }

            // Declare the x (horizontal position) scale.
            let x = null
            if (predData.data !== null){
                x = d3.scaleTime(d3.extent(predData.data, d => d.t), [0, width - margin.right]);
            } else {
                x = d3.scaleTime(d3.extent(waterData.data, d => d.t), [0, width - margin.right]);
            }

            // Declare the y (vertical position) scale.
            let y = null
            if (waterData.data !== null) {
                y = d3.scaleLinear([Math.floor(d3.min(waterData.data, d => d.v)) - 1, Math.ceil(d3.max(waterData.data, d => d.v)) + 1], [height - margin.bottom, margin.top]);
            } else {
                y = d3.scaleLinear([Math.floor(d3.min(predData.data, d => d.v)) - 1, Math.ceil(d3.max(predData.data, d => d.v)) + 1], [height - margin.bottom, margin.top]);
            }

            // Create a div that holds three svg elements: one for the main chart and horizontal axis,
            // which moves as the user scrolls the content; the other for the vertical axis (which
            // doesn’t scroll); the last for the horizontal axis label and legend.
            // First, attampt to clear the ref if it contains objects
            if (ref.current){
                d3.select(ref.current).selectAll("*").remove();
            }

            const parent = d3.select(ref.current)
                .append("div");

			// Create the svg with the vertical axis scale strokes.
			const yAxis = parent.append("svg")
				.attr("width", width - 110)
				.attr("height", height)
				.attr("transform", `translate(${0},0)`)
				.style("position", "absolute")
				.style("pointer-events", "none")
				.style("z-index", 1);

            // vertical axis scale strokes
			yAxis.append("g")
                .attr("transform", `translate(${margin.left + margin.right},0)`)
                .call(d3.axisLeft(y).ticks(6).tickFormat(y => `${y.toFixed(1)}`))
                .attr("stroke-opacity", 0)
                .call(g => g.selectAll(".tick text")
                    .attr("opacity", 0))
                .call(g => g.selectAll(".tick line").clone()
                    .attr("x2", width)
                    .attr("stroke-opacity", 0.1))
  				.call(g => g.select(".domain").remove());

            // Create the svg with the vertical axis labeling
            const yCover = parent.append("svg")
                .attr("width", 50)
                .attr("height", height)
                .attr("transform", `translate(${0},0)`)
                .style("position", "absolute")
                .style("pointer-events", "none")
                .style("z-index", 1);

            yCover.append("text")
                .attr("text-anchor", "end")
                .attr("y", margin.right - 10)
                .attr("x", -height / 2 + margin.top + margin.bottom)
                .attr("dy", "1em")
                .attr("transform", `translate(${margin.left},${height / 2})`)
                .attr("transform", "rotate(-90)")
                .style("font-family", "Roboto, sans-serif")
                .text("Feet in " + datumSelect);

            yCover.append("g")
                .attr("transform", `translate(${margin.left + margin.right + 15},0)`)
                .call(d3.axisLeft(y).ticks(6).tickFormat(y => `${y.toFixed(1)}`))
                .call(g => g.select(".domain").remove());


            // Create the svg with the horizontal axis label.
            const xAxis = parent.append("svg")
                .attr("width", width / 2)
                .attr("height", margin.bottom / 2)
                .attr("transform", `translate(${100},${height - margin.bottom / 2 - 5})`)
                .style("position", "absolute")
                .style("pointer-events", "none")
                .style("z-index", 1);

            xAxis.append("text")
                .attr("text-anchor", "start")
                .attr("dx", "1em")
                .attr("transform", `translate(${margin.left},${margin.top + 8})`)
                .style("font-family", "Roboto, sans-serif")
                .text("Time (" + getTimeZone() + ")");

            if (waterData.data !== null && predData.data !== null){
                xAxis.append("circle")
                    .attr("cx", 150)
                    .attr("cy", 6)
                    .attr("r", 3)
                    .style("fill", "red");

                xAxis.append("text")
                    .attr("x", 160)
                    .attr("y", 7 + 2)
                    .text("Observed")
                    .style("font-family", "Roboto, sans-serif")
                    .style("font-size", "10px")
                    .attr("alignment-baseline","middle");

                xAxis.append("circle")
                    .attr("cx", 150)
                    .attr("cy", 18)
                    .attr("r", 3)
                    .style("fill", "steelblue");

                xAxis.append("text")
                    .attr("x", 160)
                    .attr("y", 18 + 4)
                    .text("Predictions")
                    .style("font-family", "Roboto, sans-serif")
                    .style("font-size", "10px")
                    .attr("alignment-baseline","middle");
            } else if (predData.data !== null) {
                xAxis.append("circle")
                    .attr("cx", 150)
                    .attr("cy", 13)
                    .attr("r", 3)
                    .style("fill", "steelblue");

                xAxis.append("text")
                    .attr("x", 160)
                    .attr("y", 13 + 4)
                    .text("Predictions")
                    .style("font-family", "Roboto, sans-serif")
                    .style("font-size", "10px")
                    .attr("alignment-baseline","middle");
            } else if (waterData.data !== null) {
                xAxis.append("circle")
                    .attr("cx", 150)
                    .attr("cy", 13)
                    .attr("r", 3)
                    .style("fill", "red");

                xAxis.append("text")
                    .attr("x", 160)
                    .attr("y", 13 + 4)
                    .text("Observed")
                    .style("font-family", "Roboto, sans-serif")
                    .style("font-size", "10px")
                    .attr("alignment-baseline","middle");
            }

            // Create a scrolling div containing the area shape and the horizontal axis.
			const body = parent.append("div")
				.style("overflow-x", "scroll")
				.style("-webkit-overflow-scrolling", "touch")
                .style("position", "relative")
                .style("margin-left", `${50}px`)
                .style("margin-right", `${20}px`)
                .style("z-index", 3);

			// Create the area svg container for the horizontal axis
			const svg = body.append("svg")
                .attr("width", width)
                .attr("height", height)
                .attr("transform", `translate(${0},0)`)
                .on("pointerenter pointermove", pointermoved)
                .on("pointerleave", pointerleft)
                .style("display", "block");

            svg.append("g")
                .attr("transform", `translate(${0},${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(6).tickSizeOuter(0))
                .call(g => g.selectAll(".tick line").clone()
                    .attr("y2", -height)
                    .attr("stroke-opacity", 0.1));

            if (waterData.data !== null) {
                // add the Line
                var waterLine = d3.line()
                    .defined(d => !isNaN(d.v))
                    .x((d) => { return x(d.t); })
                    .y((d) => { return y(d.v); });

                svg.append("path")
                    .data([waterData.data])
                    .attr("transform", `translate(${0},0)`)
                    .attr("class", "line")
                    .attr("fill", "none")
                    .attr("stroke", "red")
                    .attr("stroke-width", 1.5)
                    .attr("d", waterLine);
            }

            if (predData.data !== null) {
                // add the Line
                var predLine = d3.line()
                    .defined(d => !isNaN(d.v))
                    .x((d) => { return x(d.t); })
                    .y((d) => { return y(d.v); });

                svg.append("path")
                    .data([predData.data])
                    .attr("transform", `translate(${0},0)`)
                    .attr("class", "line")
                    .attr("fill", "none")
                    .attr("stroke", "steelblue")
                    .attr("stroke-width", 1.5)
                    .attr("d", predLine);
            }

            // Create the tooltip container.
            const tooltip = svg.append("g");

            /*
            * formatValue
            * Format value into string with value interpreted as feet
            *
            * Returns string of <title> : <value>ft
            */
            function formatValue(title, value) {
            	if (!(isNaN(value))) {
	                return title + ' : ' + value.toLocaleString("en-US", {
	                  style: "unit",
	                  unit: "foot"
	                });
	            } else {
                    return title + ' : No data available';
	            }
            }

            // Add the event listeners that show or hide the tooltip.
            const bisect = d3.bisector(d => d.t).center;

            /*
            * pointermoved
            * Controls tooltip behavior when pointer enters the svg
            */
            function pointermoved(event) {
                var pointerX = d3.pointer(event)[0];
                var i = null;
                tooltip.style("display", null);

                // Conditions for what line the tooltip follows if data is present
                if (predData.data !== null) {
                    i = bisect(predData.data, x.invert(pointerX));
                    if (!isNaN(predData.data[i].v)) {
                        tooltip.attr("transform", `translate(${x(predData.data[i].t)},${y(predData.data[i].v)})`);
                    } else {
                        tooltip.style("display", "none")
                    }
                } else if (waterData.data !== null) {
                    i = bisect(waterData.data, x.invert(pointerX));
                    if (!isNaN(waterData.data[i].v)) {
                        tooltip.attr("transform", `translate(${x(waterData.data[i].t)},${y(waterData.data[i].v)})`);
                    } else {
                        tooltip.style("display", "none")
                    }
                } else {
                    tooltip.style("display", "none")
                }

                const path = tooltip.selectAll("path")
                    .data([,])
                    .join("path")
                        .attr("transform", `translate(${0},0)`)
                        .attr("fill", "white")
                        .attr("stroke", "black");

                const text = tooltip.selectAll("text")
                    .data([,])
                    .join("text")

                const circle = tooltip.selectAll("circle")
                    .data([,])
                    .join("circle")
                        .attr("r", 3)
                        .style("fill", "steelblue");

                var diff = null
                if (waterData.data !== null && predData.data !== null){
                    if (!isNaN(waterData.data[i].v)){
                        diff = y(waterData.data[i].v) - y(predData.data[i].v);
                        const circleTwo = tooltip.selectAll("circleTwo")
                            .data([,])
                            .join("circle")
                                .attr("r", 3)
                                .style("fill", "red");

                        text.call(text => text
                            .selectAll("tspan")
                            .data([tooltipDateString(predData.data[i].t), formatValue('Observed', waterData.data[i].v), formatValue('Predictions', predData.data[i].v)])
                            .join("tspan")
                                .attr("x", 0)
                                .attr("y", (_, i) => `${i * 1.1}em`)
                                .attr("font-weight", (_, i) => i ? null : "bold")
                                .style("font-family", "Roboto, sans-serif")
                                .style("fill", (_, i) => (i === 1) ? "red" : _)
                                .style("fill", (_, i) => (i === 2) ? "steelblue" : _)
                                .attr("font-size", '12px')
                                .text(d => d));

                        sizeTwo(text, path, circle, circleTwo, diff, pointerX);
                    } else {
                        text.call(text => text
                            .selectAll("tspan")
                            .data([tooltipDateString(predData.data[i].t), formatValue('Predictions', predData.data[i].v)])
                            .join("tspan")
                                .attr("x", 0)
                                .attr("y", (_, i) => `${i * 1.1}em`)
                                .attr("font-weight", (_, i) => i ? null : "bold")
                                .style("font-family", "Roboto, sans-serif")
                                .style("fill", (_, i) => (i === 1) ? "steelblue" : _)
                                .attr("font-size", '12px')
                                .text(d => d));
                        size(text, path, circle, pointerX)
                    }

                } else if (predData.data !== null) {
                    text.call(text => text
                        .selectAll("tspan")
                        .data([tooltipDateString(predData.data[i].t), formatValue('Predictions', predData.data[i].v)])
                        .join("tspan")
                            .attr("x", 0)
                            .attr("y", (_, i) => `${i * 1.1}em`)
                            .attr("font-weight", (_, i) => i ? null : "bold")
                            .style("font-family", "Roboto, sans-serif")
                            .style("fill", (_, i) => (i === 1) ? "steelblue" : _)
                            .attr("font-size", '12px')
                            .text(d => d));
                    size(text, path, circle, pointerX);
                } else if (waterData.data !== null) {
                    const circleThree = tooltip.selectAll("circle")
                        .data([,])
                        .join("circle")
                            .attr("r", 3)
                            .style("fill", "red");

                    text.call(text => text
                        .selectAll("tspan")
                        .data([tooltipDateString(waterData.data[i].t), formatValue('Observed', waterData.data[i].v)])
                        .join("tspan")
                            .attr("x", 0)
                            .attr("y", (_, i) => `${i * 1.1}em`)
                            .attr("font-weight", (_, i) => i ? null : "bold")
                            .style("font-family", "Roboto, sans-serif")
                            .style("fill", (_, i) => (i === 1) ? "red" : _)
                            .attr("font-size", '12px')
                            .text(d => d));
                    size(text, path, circleThree, pointerX);
                }

            }

            /*
            * pointerleft
            * Hides tooltip when pointer leaves the svg
            */
            function pointerleft() {
                tooltip.style("display", "none")
            }

            // Wraps the text with a callout path of the correct size, as measured in the page.
            // Fits one line for date and value stacked
            function size(text, path, circle, i) {
                const {x, y, width: w, height: h} = text.node().getBBox();

                circle.attr("transform", `translate(${0},0)`);
                if (i <= 170) {
                    text.attr("transform", `translate(${x + 20},${y + 13})`);
                    path.attr("d", `M10,${-h + 8}H${w + 30}v${h + 20}h${-w - 20}z`);
                } else if (i > 170  && i <= 240) {
                    text.attr("transform", `translate(${-w / 2},${15 - y})`);
                    path.attr("d", `M${-w / 2 - 10},5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
                } else if (i > 240) {
                    text.attr("transform", `translate(${-w - 18},${y + 13})`);
                    path.attr("d", `M${-w - 25},${-h + 8}H${-10}v${h + 20}h${-w - 15}z`);
                }
            }

            // Wraps the text with a callout path of the correct size, as measured in the page.
            // Fits two lines for date and two values stacked
            function sizeTwo(text, path, circle, circleTwo, diff, i) {
                const {x, y, width: w, height: h} = text.node().getBBox();

                circle.attr("transform", `translate(${0},${0})`);
                if (diff !== null){
                    circleTwo.attr("transform", `translate(${0},${diff})`);
                } else {
                    circleTwo.style("opacity", 0);
                }

                if (i <= 170) {
                    text.attr("transform", `translate(${x + 20},${y})`);
                    path.attr("d", `M10,${-h + 8}H${w + 30}v${h + 20}h${-w - 20}z`);
                } else if (i > 170 && i <= 240) {
                    text.attr("transform", `translate(${-w / 2},${15 - y})`);
                    path.attr("d", `M${-w / 2 - 10},5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
                } else if (i > 240) {
                    text.attr("transform", `translate(${-w - 22},${y})`);
                    path.attr("d", `M${-w - 30},${-h + 8}H${-15}v${h + 20}h${-w - 15}z`);
                }

            }

        }
    }, [ref, waterData, predData, datumSelect]);

    var waterMsg = null
    if (typeof(waterError) === "string") {
        waterMsg = <Typography sx={sxStyles.errorText} align="center">{waterError}</Typography>
    }
    var predMsg = null
    if (typeof(predError) === "string") {
        predMsg = <Typography sx={sxStyles.errorText} align="center">{predError}</Typography>
    }

    if (ref === null) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    } else{
        return(
            <>
	           <div className="parent" ref={ref}/>
               {datumOptions}
               {(waterMsg) ? waterMsg : null}
               {(predMsg) ? predMsg : null}
            </>
        );
    };

}

/**
* Component for rendering time series graphs from COOPS tide stations for
* Great Lakes stations
*
* @prop (string) waterURL - string used to query water level observation data
* @prop (string) datum - string used for datum argument in query for water level observation data
**/
const GreatLakesCharts = (props) => {
    const [waterData, setWaterData] = useState(null)
    const ref = useRef()
    const [waterTries, setWaterTries] = useState(10)

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // This useEffect uses props.waterURL to query the data
    useEffect(() => {
        async function getJSON(queryUrl, setChartData) {
            let response = await fetch(queryUrl)
            let responseData = await response.text();
            let parsedData = JSON.parse(responseData);
            const aData = parsedData.data
            if (parsedData.hasOwnProperty('data') && parsedData.data.length !== 1) {
                const slimData = aData.map(({s, ...rest}) => {
                    return rest;
                });
                slimData.forEach((d) => {
                    d.t = changeTimezoneGMT(new Date(d.t), timeZone);
                    d.v = +parseFloat(d.v);
                });
                setWaterData(slimData)
            } else if (parsedData.hasOwnProperty('data') && parsedData.data.length === 1 && waterTries > 0) {
                setWaterTries(waterTries - 1)
            } else if (parsedData.hasOwnProperty('error')) {
                const errorMsg = parsedData.error.message
            }
        }
        getJSON(props.waterURL, setWaterData);

    }, [props.waterURL, setWaterData])

    // The useEffect defines the time series object
    useEffect(() => {
        if (waterData !== null) {

            // set the dimensions and margins of the graph
            var margin = { top: 10, right: 10, bottom: 50, left: 25 },
            width = 500 - margin.left - margin.right,
            height = 300 - margin.top - margin.bottom;

            // Declare the x (horizontal position) scale.
            const x = d3.scaleTime(d3.extent(waterData, d => d.t), [0, width - margin.right]);

            // Declare the y (vertical position) scale.
            const y = d3.scaleLinear([Math.floor(d3.min(waterData, d => d.v)) - .5, Math.ceil(d3.max(waterData, d => d.v)) + .5], [height - margin.bottom, margin.top]);

            // Create a div that holds two svg elements: one for the main chart and horizontal axis,
            // which moves as the user scrolls the content; the other for the vertical axis (which
            // doesn’t scroll).
            // First, attampt to clear the ref if it contains objects
            if (ref.current){
                d3.select(ref.current).selectAll("*").remove();
            }

            const parent = d3.select(ref.current)
                .append("div");

            // Create the svg with the vertical axis.
            const yAxis = parent.append("svg")
                .attr("width", width - 110)
                .attr("height", height)
                .attr("transform", `translate(${0},0)`)
                .style("position", "absolute")
                .style("pointer-events", "none")
                .style("z-index", 1);

            if (props.datum === "LWD") {
                yAxis.append("g")
                    .attr("transform", `translate(${margin.left + margin.right + 15},0)`)
                    .call(d3.axisLeft(y).ticks(6).tickFormat(y => `${y.toFixed(1)}`))
                    .attr("stroke-opacity", 0)
                    .call(g => g.selectAll(".tick text")
                        .attr("opacity", 0))
                    .call(g => g.selectAll(".tick line").clone()
                        .attr("x2", width)
                        .attr("stroke-opacity", 0.1))
                    .call(g => g.select(".domain").remove());
            } else if (props.datum === "IGLD") {
                yAxis.append("g")
                    .attr("transform", `translate(${margin.left + margin.right + 30},0)`)
                    .call(d3.axisLeft(y).ticks(6).tickFormat(y => `${y.toFixed(1)}`))
                    .attr("stroke-opacity", 0)
                    .call(g => g.selectAll(".tick text")
                        .attr("opacity", 0))
                    .call(g => g.selectAll(".tick line").clone()
                        .attr("x2", width)
                        .attr("stroke-opacity", 0.1))
                    .call(g => g.select(".domain").remove());
            }

            const yCover = parent.append("svg")
                .attr("height", height)
                .attr("transform", `translate(${0},0)`)
                .style("position", "absolute")
                .style("pointer-events", "none")
                .style("z-index", 1);

            if (props.datum === "LWD") {
                yCover.attr("width", 50)
                yCover.append("text")
                    .attr("text-anchor", "end")
                    .attr("y", margin.right - 10)
                    .attr("x", -height / 2 + margin.top + margin.bottom)
                    .attr("dy", "1em")
                    .attr("transform", `translate(${margin.left},${height / 2})`)
                    .attr("transform", "rotate(-90)")
                    .style("font-family", "Roboto, sans-serif")
                    .text("Inches above LWD");

                yCover.append("g")
                    .attr("transform", `translate(${margin.left + margin.right + 15},0)`)
                    .call(d3.axisLeft(y).ticks(6).tickFormat(y => `${y.toFixed(1)}`))
                    .call(g => g.select(".domain").remove());
            } else if (props.datum === "IGLD") {
                yCover.attr("width", 70)
                yCover.append("text")
                    .attr("text-anchor", "end")
                    .attr("y", margin.right - 10)
                    .attr("x", -height / 2 + margin.top + margin.bottom)
                    .attr("dy", "1em")
                    .attr("transform", `translate(${margin.left},${height / 2})`)
                    .attr("transform", "rotate(-90)")
                    .style("font-family", "Roboto, sans-serif")
                    .text("Feet on IGLD");

                yCover.append("g")
                    .attr("transform", `translate(${margin.left + margin.right + 30},0)`)
                    .call(d3.axisLeft(y).ticks(6).tickFormat(y => `${y.toFixed(1)}`))
                    .call(g => g.select(".domain").remove());
            }

            // Create the svg with the horizontal axis label.
            const xAxis = parent.append("svg")
                .attr("width", width / 2)
                .attr("height", margin.bottom / 2)
                .attr("transform", `translate(${100},${height - margin.bottom / 2 - 5})`)
                .style("position", "absolute")
                .style("pointer-events", "none")
                .style("z-index", 1);

            xAxis.append("text")
                .attr("text-anchor", "start")
                .attr("dx", "1em")
                .attr("transform", `translate(${margin.left},${margin.top + 8})`)
                .style("font-family", "Roboto, sans-serif")
                .text("Time (" + getTimeZone() + ")");

            xAxis.append("circle")
                .attr("cx", 150)
                .attr("cy", 13)
                .attr("r", 3)
                .style("fill", "red");

            xAxis.append("text")
                .attr("x", 160)
                .attr("y", 13 + 4)
                .text("Observed")
                .style("font-size", "10px")
                .style("font-family", "Roboto, sans-serif")
                .attr("alignment-baseline","middle");

            // Create a scrolling div containing the area shape and the horizontal axis.
            const body = parent.append("div")
                .style("overflow-x", "scroll")
                .style("-webkit-overflow-scrolling", "touch")
                .style("position", "relative")
                .style("margin-right", `${20}px`)
                .style("z-index", 3);

            // Create the area svg container for the horizontal axis
            const svg = body.append("svg")
                .attr("width", width)
                .attr("height", height)
                .attr("transform", `translate(${0},0)`)
                .on("pointerenter pointermove", pointermoved)
                .on("pointerleave", pointerleft)
                .style("display", "block");

            if (props.datum === "LWD") {
                body.style("margin-left", `${50}px`)
                svg.append("g")
                    .attr("transform", `translate(${0},${height - margin.bottom})`)
                    .call(d3.axisBottom(x).ticks(6).tickSizeOuter(0))
                    .call(g => g.selectAll(".tick line").clone()
                        .attr("y2", -height)
                        .attr("stroke-opacity", 0.1));
            } else if (props.datum === "IGLD") {
                body.style("margin-left", `${70}px`)
                svg.append("g")
                    .attr("transform", `translate(${0},${height - margin.bottom})`)
                    .call(d3.axisBottom(x).ticks(6).tickSizeOuter(0))
                    .call(g => g.selectAll(".tick line").clone()
                        .attr("y2", -height)
                        .attr("stroke-opacity", 0.1));
            }

            // add the Line
            var waterLine = d3.line()
                .defined(d => !isNaN(d.v))
                .x((d) => { return x(d.t); })
                .y((d) => { return y(d.v); });

            const waterPath = svg.append("path")
                .data([waterData])
                .attr("class", "line")
                .attr("fill", "none")
                .attr("stroke", "red")
                .attr("stroke-width", 1.5)
                .attr("d", waterLine);

            if (props.datum === "LWD") {
                waterPath.attr("transform", `translate(${0},0)`);
            } else if (props.datum === "IGLD") {
                waterPath.attr("transform", `translate(0,0)`);
            }

            // Create the tooltip container.
            const tooltip = svg.append("g");

            /*
            * formatInch
            * Format value into string with value interpreted as inches
            *
            * Returns string of <title> : <value>in
            */
            function formatInch(title, value) {
                if (!(isNaN(value))) {
                    return title + ' : ' + value.toLocaleString("en-US", {
                      style: "unit",
                      unit: "inch"
                    });
                } else {
                    return title + ' : No data available';
                }
            }

            /*
            * formatFoot
            * Format value into string with value interpreted as feet
            *
            * Returns string of <title> : <value>ft
            */
            function formatFoot(title, value) {
                if (!(isNaN(value))) {
                    return title + ' : ' + value.toLocaleString("en-US", {
                      style: "unit",
                      unit: "foot"
                    });
                } else {
                    return title + ' : No data available';
                }
            }

            // Add the event listeners that show or hide the tooltip.
            const bisect = d3.bisector(d => d.t).center;

            /*
            * pointermoved
            * Controls tooltip behavior when pointer enters the svg
            */
            function pointermoved(event) {
                var pointerX = d3.pointer(event)[0]
                var i = null;
                if (props.datum === "LWD") {
                    i = bisect(waterData, x.invert(pointerX));
                } else if (props.datum === "IGLD") {
                    i = bisect(waterData, x.invert(pointerX));
                }

                tooltip.style("display", null);
                if (!isNaN(waterData[i].v)) {
                    tooltip.attr("transform", `translate(${x(waterData[i].t)},${y(waterData[i].v)})`);
                } else {
                    tooltip.style("display", "none")
                }

                const path = tooltip.selectAll("path")
                    .data([,]);

                if (props.datum === "LWD") {
                    path.join("path")
                        .attr("transform", `translate(${0},0)`)
                        .attr("fill", "white")
                        .attr("stroke", "black");
                } else if (props.datum === "IGLD") {
                    path.join("path")
                        .attr("transform", `translate(${0},0)`)
                        .attr("fill", "white")
                        .attr("stroke", "black");
                }

                const text = tooltip.selectAll("text")
                    .data([,])
                    .join("text")
                    .call(text => text
                        .selectAll("tspan")
                        .data([tooltipDateString(waterData[i].t), ((props.datum === "LWD") ? formatInch('Observed', waterData[i].v) : formatFoot('Observed', waterData[i].v))])
                        .join("tspan")
                            .attr("x", 0)
                            .attr("y", (_, i) => `${i * 1.1}em`)
                            .attr("font-weight", (_, i) => i ? null : "bold")
                            .style("fill", (_, i) => (i === 1) ? "red" : _)
                            .attr("font-size", '12px')
                            .style("font-family", "Roboto, sans-serif")
                            .text(d => d));

                const circle = tooltip.selectAll("circle")
                    .data([,])
                    .join("circle")
                        .attr("r", 3)
                        .style("fill", "red");

                size(text, path, circle, pointerX, props.datum);
            }

            /*
            * pointerleft
            * Hides tooltip when pointer leaves the svg
            */
            function pointerleft() {
                tooltip.style("display", "none")
            }

            // Wraps the text with a callout path of the correct size, as measured in the page.
            function size(text, path, circle, i, datum) {
                const {x, y, width: w, height: h} = text.node().getBBox();

                if (datum === "LWD") {
                    circle.attr("transform", `translate(${0},0)`);
                    if (i <= 170) {
                        text.attr("transform", `translate(${x + 20},${y + 13})`);
                        path.attr("d", `M10,${-h + 8}H${w + 30}v${h + 20}h${-w - 20}z`);
                    } else if (i > 170  && i <= 240) {
                        text.attr("transform", `translate(${-w / 2},${15 - y})`);
                        path.attr("d", `M${-w / 2 - 10},5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
                    } else if (i > 240) {
                        text.attr("transform", `translate(${-w - 18},${y + 13})`);
                        path.attr("d", `M${-w - 25},${-h + 8}H${-10}v${h + 20}h${-w - 15}z`);
                    }
                } else if (datum === "IGLD"){
                    circle.attr("transform", `translate(${0},0)`);
                    if (i <= 170) {
                        text.attr("transform", `translate(${x + 20},${y + 13})`);
                        path.attr("d", `M10,${-h + 8}H${w + 30}v${h + 20}h${-w - 20}z`);
                    } else if (i > 170 && i <= 240) {
                        text.attr("transform", `translate(${-w / 2},${15 - y})`);
                        path.attr("d", `M${-w / 2 - 10},5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
                    } else if (i > 240) {
                        text.attr("transform", `translate(${-w - 15},${y + 13})`);
                        path.attr("d", `M${-w - 25},${-h + 8}H${-10}v${h + 20}h${-w - 15}z`);
                    }
                }
            }
        }
    }, [ref, waterData, props]);

    if (ref === null) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    } else if (typeof(waterData) === "string") {
        return(<Typography align="center">waterData</Typography>)
    } else {
        return(
            <>
                <div className="parent" ref={ref}/>
            </>
        );
    };

}



export { WaterLevelChart, GreatLakesCharts }