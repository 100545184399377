import React, { useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';

import FeatureInfoAccordion from './feature-info-accordion'
import useHighlightLayer from './hooks/use-highlight-layer'

import { OL_ZINDEXES, WWA_COLORS } from '../../config';


/**
* Parse vtec codes. For detailed info on NWS' VTEC Codes see: https://www.weather.gov/vtec/
*
* Args:
*   code (`str`) - vtec code from tropical cyclones get feature info (ex. "/O.NEW.KLIX.FA.W.0002.220610T1943Z-220610T2145Z/")
*   NOTE: Code can be null (certain advisories/statements will not have vtec codes)
*
* Returns:
*   String containing just the Phenomena and Significance symbols from the vtec code, combined together
*       Significance values: W (Warning), A (Watch), Y (Advisory)
*       Phenomena values consist of 1-2 letter abbreviations for various weather events
*       In the example code above, the output would be FAW
*/
function parseVTEC(code) {
    try {
        if (code.length===3) {
            return(code)
        }
        else{
            const tokens = code.split(".");
            return(tokens[3] + tokens[4]);
        }
    } catch {
        return("n/a");
    }
}

const sxStyles = {
    tableContainer: {
    },
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        border: 'none',
    },
    bulletinLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        }
    },
};

// JSS styles for non-mui components
const classes = {
    wwaFeatureInfoContainer: {
        marginTop: '10px',
    },
}


/**
* Component for rendering results from getFreatureInfo wms requests
*
* @prop (obj) map - OL map object used for drawing feature info highlights
* @prop (obj) data - json response obj from getFreatureInfo request
**/
export default function WWAFeatureInfo(props) {

    const [productsOpen, setProductsOpen] = useState(() => {
        if (props.data && props.data.features) {
            const initialProductsOpen = {}
            // Switch firstProduct to true to utilize branch below to allow first accordion to be open by default if desired
            let firstProduct = false
            for (const feature of props.data.features) {
                if (firstProduct) {
                    // Set first product here to true. First accordion must be set to be open by default below as well
                    // in order to match that setting
                    initialProductsOpen[feature.properties.prod_type] = true;
                    firstProduct = false;
                } else {
                    if (!(feature.properties.prod_type in initialProductsOpen)) {
                        initialProductsOpen[feature.properties.prod_type] = false;
                    }
                }
            }
            return(initialProductsOpen);
        } else {
            return({});
        }
    });

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.wwa_highlight_layer);

    // Handle drawing of GeoJSON features returned by getFeatureInfo
    clearHighlight();
    if (Object.keys(props.data).length > 0) {
        if (props.data && props.data.features  ) {
            for (const feature of props.data.features) {
                if (productsOpen[feature.properties.prod_type] && productsOpen[feature.properties.prod_type] === true) {
                    drawFeatureHighlight(feature);
                }
            }
        }
    }

    if (Object.keys(props.data).length === 0) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    }

    if (props.data.features.length === 0) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location. Please try another location, toggle desired layers on/off, or adjust the time control, then try again.</Typography>
            </div>
        );
    }

    // Get all unique product types
    const productTypes = new Set();
    for (const feature of props.data.features) {
        productTypes.add(feature.properties.prod_type);
    }

    let content = [...productTypes].map((prodType,index) => {
        return (
            <FeatureInfoAccordion key={index}
                styleOverride={{borderLeft: "10px solid " + WWA_COLORS[CapitolToCamelCase(prodType)].color, boxShadow: "3px 1px 0px -2px rgba(0,0,0,0.15) inset"}}
                featureName={prodType}
                defaultExpanded={false}
                setFeatureIsOn={() => {setProductsOpen((prevState) => {return({...prevState, [prodType]: !prevState[prodType]})})}}
            >
                <div style={{padding: '5px 0px 10px 0px'}}>
                {
                    props.data.features.map((feature, index) => {
                        if (prodType === feature.properties.prod_type) {
                            return(
                                <TableContainer sx={sxStyles.tableContainer} key={index}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow sx={sxStyles.tableHeader}>
                                                <TableCell><Link href={feature.properties.url} sx={sxStyles.bulletinLink} target="_blank" rel="noopener noreferrer">View Text Bulletin</Link></TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Issuance Time/Date:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.issuance}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Expected (or actual) Event Beginning Time/Date:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.onset}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Expected Event Ending Time/Date:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.ends}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Issuing NWS WFO or Center (ID):</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.wfo}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>VTEC Code:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{parseVTEC(feature.properties.vtec)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Message Type:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.msg_type}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            );
                        }
                    })
                }
                </div>
            </FeatureInfoAccordion>
        )
    });

    return(
        <div style={classes.wwaFeatureInfoContainer}>
            {content}
        </div>
    );
}

/**
* Convert a string from "Capitol Case" to "camelCase"
* Takes string consisting of space-separated words that start with capital letters
* Returns the same string in camel case
*/
function CapitolToCamelCase(word) {
    let first_iter = true;
    let newWord = "";
    for (const part of word.split(" ")) {
        let newPart = part;
        if (first_iter) {
            newPart = part.toLowerCase();
            first_iter = false;
        }
        newWord = newWord + newPart;
    }
    return newWord;
}
